export const convertMonthsToOldFormat = (message: any): any => {
  if (Array.isArray(message.months)) {
    return convertMonthsToOldFormat(message);
  } else if (typeof message.months === 'object') {
    // Si c'est un objet, transformons-le en tableau pour qu'il corresponde à l'ancien format
    return {
      ...message,
      months: Object.keys(message.months).reduce((acc: { [key: string]: number }, key) => {
        const month = message.months[key];
        acc[month.label] = month.value;
        return acc;
      }, {}),
    };
  } else {
    console.error("La structure de 'months' est incorrecte ou pas un tableau");
    return message;
  }
};
