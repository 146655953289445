// MaterialUI
import { Card, Box } from "@mui/material";
import { useEffect, useState } from "react";
import Turnover from "../../components/Forecast/Display/Turnover";
import Salaries from "../../components/Forecast/Display/Salaries";
import Fees from "../../components/Forecast/Display/Fees";
import ForecastTotal from "../../components/Forecast/Display/ForecastTotal";
import AnnualForecastTotal from "../../components/Forecast/Display/AnnualForecastTotal";
import { useLocation, useNavigate, useParams } from "react-router";
import { sessionEnd, showSnackbar } from "../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { IForecast } from "../../interface/forecast";
import { getOne } from "../../fetchs/get";
import { RootState } from "../../redux/reducers";
import getForecastDates from "../../tools/forecast/getForecastDates";
import ForecastHeader from "../../components/Forecast/Display/ForecastHeader";
import TNS from "../../components/Forecast/Display/TNS";
import Invests from "../../components/Forecast/Display/Invests";
import Company from "../../components/Forecast/Display/Company";
import Subcontract from "../../components/Forecast/Display/Subcontract";
import DGFIP from "../../components/Forecast/Display/DGFIP";
import Saving from "../../components/Forecast/Display/Saving";

const ForecastDetails = () => {
  let dispatch = useDispatch();
  let navigate = useNavigate();
  let token = useSelector((state: RootState) => {
    return state.user.token;
  });
  const { state } = useLocation();
  const params = useParams();
  const [error, setError] = useState("");

  const [forecast, setForecast] = useState<IForecast>();
  const [forecastDates, setForecastDates] = useState<string>("");
  const [activeTabs, setActiveTabs] = useState<string[]>(["Chiffre d'Affaires"]);
  const [version, setVersion] = useState<number>(0);
  const [official, setOfficial] = useState<boolean>(false);

  useEffect(() => {
    if (state === "addFeeSuccess") {
      dispatch(showSnackbar("Frais de fonctionnement créé avec succès", "success"));
    }
  }, [state]);

  const getForecast = async () => {
    if (token) {
      getOne(token, '/forecast/', params.id ? params.id : "", setForecast,
        () => {
          dispatch(sessionEnd());
        }, setError
      )
    }
  }

  useEffect(() => {
    getForecast()
  }, [])

  useEffect(() => {
    forecast?.versions.forEach((version) => {
      if (version.official) {
        setVersion(version.number);
        setOfficial(version.official);
      }
    })
  }, [forecast]);

  useEffect(() => {
    forecast?.versions.map((forecastVersion) => {
      if (forecastVersion.number == version) {
        setOfficial(forecastVersion.official);
      }
    })
  }, [version])

  useEffect(() => {
    const fetchForecastDates = async () => {
      if (forecast) {
        const dates = await getForecastDates(forecast.startDate, forecast.lenght);
        setForecastDates(dates);
      }
    };

    fetchForecastDates();
  }, [forecast]);

  const forecastTabs = [
    { title: "Chiffre d'Affaires", component: forecastDates ? (<Turnover forecastVersion={version} forecastDates={forecastDates} />) : (<p>Loading...</p>) },
    { title: "Salariés", component: forecastDates ? (<Salaries forecastVersion={version} forecastDates={forecastDates} />) : (<p>Loading...</p>) },
    { title: "TNS", component: forecastDates ? (<TNS forecastVersion={version} forecastDates={forecastDates} />) : (<p>Loading...</p>) },
    { title: "Frais de fonctionnement", component: forecastDates ? (<Fees forecastVersion={version} forecastDates={forecastDates} />) : (<p>Loading...</p>) },
    { title: "Investissements", component: forecastDates ? (<Invests forecastVersion={version} forecastDates={forecastDates} />) : (<p>Loading...</p>) },
    { title: "Entreprise", component: forecastDates ? (<Company forecastVersion={version} forecastDates={forecastDates} />) : (<p>Loading...</p>) },
    { title: "Sous-traitance", component: forecastDates ? (<Subcontract forecastVersion={version} forecastDates={forecastDates} />) : (<p>Loading...</p>) },
    { title: "DGFIP", component: forecastDates ? (<DGFIP forecastVersion={version} forecastDates={forecastDates} />) : (<p>Loading...</p>) },
    { title: "Totaux", component: forecastDates ? (<ForecastTotal forecastDates={forecastDates} />) : (<p>Loading...</p>) },
    { title: "Total prévisionnel", component: forecastDates ? (<AnnualForecastTotal forecastName={forecast?.name} forecastVersion={version} />) : (<p>Loading...</p>) }
  ];

  return (
    <div>
      <Box sx={{ flexGrow: 1 }}>
        <ForecastHeader
          forecast={forecast}
          activeTab={activeTabs}
          forecastDates={forecastDates}
          forecastTabs={forecastTabs}
          setActiveTab={setActiveTabs}
          version={version}
          setVersion={setVersion}
          official={official}
          navigate={navigate}
          dispatch={dispatch}
          setError={setError}
          getForecast={getForecast}
        />
        <Card
          elevation={0}
          component="main"
          sx={{
            backgroundColor: "white",
            m: 2,
            p: 2,
            borderRadius: "10px",
            minHeight: "calc(100vh - 164px)",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {forecastTabs.map((tab) =>
            <Box key={tab.title} sx={{ display: activeTabs.includes(tab.title) ? "" : "none" }}>
              {tab.component}
            </Box>
          )}
        </Card>
      </Box>
    </div>
  );
};

export default ForecastDetails;
