// MaterialUI
import { Card, Box, Typography } from "@mui/material";

// Img
import image from "../../img/hello.webp";

const HomePricing = () => {
  return (
    <div>
      <Card
        component="main"
        sx={{
          backgroundColor: "white",
          flexGrow: 1,
          p: 2,
          m: 1,
          height: "90vh",
        }}
      >
        <Typography variant="h2">Bienvenue sur le Chiffrage Projets</Typography>

        <div style={{ display: "flex", justifyContent: "center" }}>
          <Box
            component="img"
            sx={{
              width: { xs: "100%", lg: "60%", xl: "50%" },
            }}
            alt="image de bienvenue"
            src={image}
          />
        </div>
      </Card>
    </div>
  );
};

export default HomePricing;
