import { DataGrid, getGridStringOperators, GridColDef, GridRowsProp } from "@mui/x-data-grid";
import { Typography } from "@mui/material";
import CustomDataGrid from "../../customDataGrid/CustomDataGrid";
import { useState } from "react";

const ForecastTotalData = ({ monthList, totalBySection }: { monthList: any, totalBySection: any }) => {

    const columnsInfos: GridColDef[] = [{
        field: "section",
        headerName: "Section",
        filterable: true,
        minWidth: 200,
        filterOperators: getGridStringOperators().filter((operator) => operator.value === "contains"),
        headerClassName: "custom-header",
        renderCell: (params) => (
            <div style={{ whiteSpace: 'normal' }}>
                {params.value?.split('\n').map((line: any, index: any) => (
                    <span key={index}>
                        {line}
                        <br />
                    </span>
                ))}
            </div>
        ),
    }]

    const columnsMonth: GridColDef[] = monthList.map((column: any) => {
        return {
            field: column.field,
            headerName: column.name,
            minWidth: 160,
            headerClassName: "custom-header",
            align: "center",
            headerAlign: "center"
        }
    })

    const columns: GridColDef[] = [...columnsInfos, ...columnsMonth];

    totalBySection["TOTAL"] = monthList.reduce((acc: any, month: any) => {
        const monthField = month.field;

        acc[monthField] = (
            (totalBySection["CA - Régie"]?.[monthField] || 0) +
            (totalBySection["CA - Forfait"]?.[monthField] || 0) +
            (totalBySection["CA - Hébergement"]?.[monthField] || 0) +
            (totalBySection["CA - TMA/TME"]?.[monthField] || 0) +
            (totalBySection["CA - TRA"]?.[monthField] || 0) -
            (totalBySection["Salariés"]?.[monthField] || 0) -
            (totalBySection["TNS"]?.[monthField] || 0) -
            (totalBySection["Frais de fonctionnement"]?.[monthField] || 0) -
            (totalBySection["Investissements"]?.[monthField] || 0) -
            (totalBySection["Entreprise"]?.[monthField] || 0) -
            (totalBySection["Sous-traitance"]?.[monthField] || 0) -
            (totalBySection["DGFIP"]?.[monthField] || 0)
        );

        return acc;
    }, {});


    const rows: GridRowsProp = Object.keys(totalBySection).map((key, index) => ({
        id: key === "TOTAL" ? "total" : index, // Ajoute id: "total" pour la ligne TOTAL
        section: `\n${key}\n`,
        ...Object.fromEntries(
            Object.entries(totalBySection[key as keyof typeof totalBySection]).map(([monthKey, value]) => {
                const formattedValue = key.includes("CA -") || key === "TOTAL"
                    ? `\n${(value as number).toFixed(2)} €\n`
                    : `\n- ${(value as number).toFixed(2)} €\n`;

                return [monthKey, formattedValue];
            })
        )
    }));

    const [selectedDGFIP, setSelectedDGFIP] = useState([]);

    return (
        <>
            <Typography variant="h3" sx={{ textAlign: "center", backgroundColor: "#FFD28E" }}>Totaux</Typography>
            <CustomDataGrid
                disableRowSelectionOnClick={true}
                rows={rows}
                columns={columns}

            />

        </>
    )
}

export default ForecastTotalData;