import { Autocomplete, Button, CircularProgress, Switch, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { urlAPI } from "../../../config/config";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/reducers";

const SalariesInfosFields = ({
  salaryName,
  formik
} : {
  salaryName?: string,
  formik: any
}) => {
  const dispatch = useDispatch();
  const token = useSelector((state: RootState) => state.user.token);

  const selectInputs = [
    { id: "contract", options: ["CDI", "CDD", "Alternant", "Stagiaire"], value: formik.values.contract || null, label: "Contrat", touched: formik.touched.agency, errors: formik.errors.agency, required: true },
    { id: "agency", options: ["Nord", "Sud"], value: formik.values.agency || null, label: "Agence", touched: formik.touched.agency, errors: formik.errors.agency, required: true },
  ]

  return (
    <>
        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", width: "30%" }}>
            <TextField
                margin="normal"
                required
                type="text"
                id="fictifName"
                label={`Nom du salarié ${formik.values.collab ? "" : "fictif"}`}
                name="fictifName"
                autoComplete="fictifName"
                value={formik.values.collab ? (salaryName) : formik.values.fictifName}
                onChange={formik.handleChange}
                error={formik.touched.fictifName && Boolean(formik.errors.fictifName)}
                helperText={formik.touched.fictifName && formik.errors.fictifName}
                disabled={formik.values.collab}
                autoFocus
            />
        {
            selectInputs.map((select) => {
              return (
                <Autocomplete
                    key={select.id}
                    id={select.id}
                    options={select.options}
                    getOptionLabel={(option: any) => option }
                    isOptionEqualToValue={(option: any, value: any) => option === value }
                    value={select.value}
                    onChange={(event, value: any) => {formik.setFieldValue(select.id, value ? value : "")}}
                    renderInput={(params) => (
                        <TextField
                          {...params}
                          required={select.required}
                          name={select.id}
                          margin="normal"
                          label={select.label}
                          error={select.touched && Boolean(select.errors)}
                          helperText={select.touched && select.errors}
                        />
                    )}
                  />
              )
            })
        }
        </Box>
    </>
  )
}

export default SalariesInfosFields;