export default function getForecastDates(forecastStart: Date | string, forecastLenght: number): string {
  const forecastStartDate = new Date(forecastStart);

  if (isNaN(forecastStartDate.getTime())) {
    throw new Error("forecastStart doit être une date valide.");
  }

  const forecastEndDate = new Date(forecastStartDate);
  forecastEndDate.setMonth(forecastEndDate.getMonth() + forecastLenght - 1);

  const monthStartDate = Intl.DateTimeFormat("fr-FR", { month: "long" }).format(forecastStartDate);
  const monthEndDate = Intl.DateTimeFormat("fr-FR", { month: "long" }).format(forecastEndDate);

  return (
    `${capitalize(monthStartDate)} ${forecastStartDate.getFullYear()} / ` +
    `${capitalize(monthEndDate)} ${forecastEndDate.getFullYear()}`
  );
}

function capitalize(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1);
}
