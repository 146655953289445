import { Switch, TextField, Typography } from "@mui/material"
import { Box } from "@mui/system"
import { useEffect, useState } from "react";
import { ITNS } from "../../../interface/tns";

const DGFIPMonthFields = ({
	formik,
	monthList
}: {
	formik: any,
	monthList: { name: string, field: string }[],
}) => {
	const label = { inputProps: { 'aria-label': 'Switch demo' } };

	const [typeChecked, setTypeChecked] = useState<boolean>(false);

	useEffect(() => {
		const amountType = Object.values(formik.values.months).every(month => month === Object.values(formik.values.months)[0]);
		setTypeChecked(!amountType);
	}, [formik.values.months]);

	const [amount, setAmount] = useState<number>(0);

	const handleAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		let newAmount = parseFloat(event.target.value);
		newAmount = parseFloat(newAmount.toFixed(2));
		setAmount(newAmount);
		if (newAmount >= 0) {
			updateMonthsToConstant(newAmount);
		}
	};

	const updateMonthsToConstant = (constantValue: number) => {
		monthList.forEach((key) => {
			formik.setFieldValue(`months.${key.field}`, constantValue);
		});
	};

	const handleMonthChange = (e: any) => {
		const { name, value } = e.target;
		formik.setFieldValue(`months.${name}`, value === "" ? "" : Number(value));
	};

	return (
		<Box sx={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", width: "60%" }}>
			<Box sx={{ display: "flex", justifyContent: "center" }}>
				<Typography variant="h6" sx={{ textAlign: "center", fontWeight: !typeChecked ? "bold" : "" }}>Montant constant</Typography>
				<Switch
					{...label}
					onClick={() => {
						const newTypeChecked = !typeChecked;
						setTypeChecked(newTypeChecked);

						if (!newTypeChecked) {
							updateMonthsToConstant(amount);
						}
					}}
					checked={typeChecked || false}
				/>
				<Typography variant="h6" sx={{ textAlign: "center", fontWeight: typeChecked ? "bold" : "" }}>Montant variable</Typography>
			</Box>
			{
				!typeChecked && (
					<TextField
						margin="normal"
						required
						type="number"
						inputProps={{
							step: "0.1",
							inputMode: "decimal"
						}}
						id="amount"
						label="Montant mensuel"
						name="amount"
						autoComplete="amount"
						value={amount}
						onChange={handleAmountChange}
						// error={formik.touched.months[month.id] && Boolean(formik.errors.months[month.id])}
						// helperText={formik.touched.months[month.id] && formik.errors.months[month.id]}
						autoFocus
						sx={{ width: "40%", marginInline: "auto" }}
					/>
				)
			}
			<Box sx={{ display: "flex", flexWrap: "wrap", justifyContent: "left", columnGap: "30px" }}>
				{
					monthList.map((month) => {
						return (
							<TextField
								margin="normal"
								required
								type="number"
								inputProps={{
									step: "0.1",
									inputMode: "decimal"
								}}
								id={month.field}
								label={month.name}
								name={month.field}
								autoComplete={month.field}
								value={formik.values.months[month.field]}
								onChange={handleMonthChange}
								error={formik.touched.months?.[month.field] && Boolean(formik.errors.months?.[month.field])}
								helperText={formik.touched.months?.[month.field] && formik.errors.months?.[month.field]}
								autoFocus
								disabled={!typeChecked}
								sx={{ width: "30%" }}
							/>
						)
					})
				}
			</Box>
		</Box>
	)
}

export default DGFIPMonthFields;