import { Button, InputAdornment, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/reducers";
import { useState } from "react";
import { ISalarialMatrice, ISalaryMatriceFormValues } from "../../../interface/salaryMatrice";

const validationSchema = yup.object({
    amountTR: yup.number().required(),
    patronalTR : yup.number().required(),
    salaryPatronalContribution : yup.number().required(),
    salarySalarialContribution : yup.number().required(),
    altPatronalContribution : yup.number().required(),
    altSalarialContribution : yup.number().required(),
    internPatronalContribution : yup.number().required(),
    internSalarialContribution : yup.number().required(),
    abundance : yup.number().required(),
    abundanceContribution : yup.number().required(),
    managementFee : yup.number().required(),
});

const SalaryMatrice = ({
  handleSubmit,
  forecastId,
  version,
  setOpenModal,
  setShowConfirmDialog,
  salarialMatrice
} : {
  handleSubmit: Function;
  forecastId?: string,
  version?: number,
  setOpenModal: Function,
  setShowConfirmDialog: Function,
  salarialMatrice?: ISalarialMatrice
}) => {
  let dispatch = useDispatch();

  let token = useSelector((state: RootState) => state.user.token) as string;

  const [error, setError] = useState("");

  const formik = useFormik({
    initialValues: {
      amountTR: salarialMatrice ? salarialMatrice.amountTR : 9.48,
      patronalTR: salarialMatrice ? salarialMatrice.patronalTR : 60,
      salaryPatronalContribution: salarialMatrice ? salarialMatrice.salaryPatronalContribution : 72,
      salarySalarialContribution: salarialMatrice ? salarialMatrice.salarySalarialContribution : 23,
      altPatronalContribution: salarialMatrice ? salarialMatrice.altPatronalContribution : 0,
      altSalarialContribution: salarialMatrice ? salarialMatrice.altSalarialContribution : 0,
      internPatronalContribution: salarialMatrice ? salarialMatrice.internPatronalContribution : 0,
      internSalarialContribution: salarialMatrice ? salarialMatrice.internSalarialContribution : 0,
      abundance: salarialMatrice ? salarialMatrice.abundance : 100,
      abundanceContribution: salarialMatrice ? salarialMatrice.abundanceContribution : 0,
      managementFee: salarialMatrice ? salarialMatrice.managementFee : 83,
    },  
    validationSchema: validationSchema,
    onSubmit: (values: ISalaryMatriceFormValues) => {
      handleSubmit(token, values, forecastId, version!, setOpenModal, dispatch, setError);
    },
    enableReinitialize: true,
  });

  const fields = [
    {id : "amountTR", label : "Montant Ticket Restos", symbol : "€"},
    {id : "patronalTR", label : "Part Patronale Ticket Restos", symbol : "%"},
    {id : "salaryPatronalContribution", label : "Cotisation Patronale (Statutaire)", symbol : "%"},
    {id : "salarySalarialContribution", label : "Cotisation Salariale (Statutaire)", symbol : "%"},
    {id : "altPatronalContribution", label : "Cotisation Patronale (Alternant)", symbol : "%"},
    {id : "altSalarialContribution", label : "Cotisation Salariale (Alternant)", symbol : "%"},
    {id : "internPatronalContribution", label : "Cotisation Patronale (Stagiaire)", symbol : "%"},
    {id : "internSalarialContribution", label : "Cotisation Salariale (Stagiaire)", symbol : "%"},
    {id : "abundance", label : "Abondement Epargne", symbol : "%"},
    {id : "abundanceContribution", label : "Cotisation Abondement", symbol : "%"},
    {id : "managementFee", label : "Frais de gestion Epargne", symbol : "€"},
  ]

  return (
    <Box component="form" onSubmit={formik.handleSubmit} sx={{ mt: 1, width: "100%", display: "flex", flexWrap: "wrap", justifyContent: "center", padding: "20px", columnGap: "50px"}}>
        {fields.map((field)=>{
            return (
                <TextField
                    margin="normal"
                    required
                    type="number"
                    id={field.id}
                    label={field.label}
                    name={field.id}
                    autoComplete={field.id}
                    value={formik.values[field.id as keyof typeof formik.values]}
                    onChange={formik.handleChange}
                    error={formik.touched[field.id as keyof typeof formik.values] && Boolean(formik.errors[field.id as keyof typeof formik.values])}
                    helperText={formik.touched[field.id as keyof typeof formik.values] && formik.errors[field.id as keyof typeof formik.values]}
                    autoFocus
                    sx={{width: "300px"}}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">{field.symbol}</InputAdornment>,
                    }}
                />
            )
        })}
        <Box sx={{width: "100%"}}></Box>
        <Box sx={{ display: "flex", columnGap: "200px" }}>
            <Button type="button" variant="contained" sx={{ mt: 3, mb: 2 }} onClick={() => {setShowConfirmDialog(true)}}>Annuler</Button>
            <Button type="submit" variant="contained" sx={{ mt: 3, mb: 2 }}>Enregistrer</Button>
        </Box>
    </Box>
  )
}

export default SalaryMatrice;