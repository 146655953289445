// MaterialUI
import { Card, Box, Typography, AppBar, Toolbar, Button, IconButton, Dialog, DialogTitle, FormControl, InputLabel, Select, MenuItem } from "@mui/material";

import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { DataGrid, getGridDateOperators, GridColDef, GridRowsProp } from "@mui/x-data-grid";
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import EditTwoToneIcon from "@mui/icons-material/EditTwoTone";
import getForecastsData from "../../tools/forecast/getForecastsData";
import { useEffect, useState } from "react";
import { IForecast } from "../../interface/forecast";
import { RootState } from "../../redux/reducers";
import AddUpdateForecast from "../../components/Forecast/AddUpdate/AddUpdateForecast";
import CloseIcon from "@mui/icons-material/Close";
import getForecastDates from "../../tools/forecast/getForecastDates";
import AlerteDialog from "../../components/AlerteDialog/AlerteDialog";
import { deleteOne } from "../../fetchs/delete";
import { sessionEnd, showSnackbar } from "../../redux/actions";
import { addForecast, duplicateForecast, updateForecast } from "../../tools/forecast/addUpdateForecast";
import DuplicateForecast from "../../components/Forecast/AddUpdate/DuplicateForecast";

const HomeForecast = () => {
  let dispatch = useDispatch();
  let navigate = useNavigate();

  const [forecastData, setForecastData] = useState<IForecast[]>([]);
  const [error, setError] = useState('');
  const [openCopyModal, setOpenCopyModal] = useState<boolean>(false);
  const [openCreateModal, setOpenCreateModal] = useState<boolean>(false);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [forecastId, setForecastId] = useState<string>("");
  const [forecastInfo, setForecastInfo] = useState<IForecast>();
  const [selectedSociety, setSelectedSociety] = useState<string>("all");

  const [actionType, setActionType] = useState<"delete" | "close" | null>(null);
  const token = useSelector((state: RootState) => {
    return state.user.token;
  });

  const columnsHeaders = [
    { field: "name", name: "Nom" },
    { field: "period", name: "Période" },
    { field: "society", name: "Société" },

  ]

  const columnDate = [
    { field: "createdAt", name: "Crée le" },
    { field: "updatedAt", name: "Mis à jour le" }
  ]

  const columnAction = [
    { field: "modify", name: "Modifier", icon: <EditTwoToneIcon sx={{ color: "orange" }} /> },
    { field: "delete", name: "Supprimer", icon: <DeleteTwoToneIcon sx={{ color: "red" }} /> }
  ]

  const columns: GridColDef[] = [
    ...columnsHeaders.map((column) => {
      return ({
        field: column.field,
        headerName: column.name,
        flex: 2,
        filterable: true,
      })
    }),
    ...columnDate.map((date) => {
      return ({
        field: date.field,
        headerName: date.name,
        flex: 1,
        type: "date",
        valueGetter: (params: any) => new Date(params.row[date.field].split("/").reverse().join("-")),
        filterable: true,
        filterOperators: getGridDateOperators().filter(
          (operator) => operator.value === "is" || operator.value === "onOrAfter" || operator.value === "onOrBefore"
        ),
      }
      )
    }),
    ...columnAction.map((action) => {
      return ({
        field: action.field,
        headerName: action.name,
        cellClassName: "center-cell",
        renderCell: () => {
          return (
            <IconButton>
              {action.icon}
            </IconButton>
          );
        },
        filterable: false,
      }
      )
    })
  ];

  const rows: GridRowsProp = forecastData
    .filter(forecast => selectedSociety === "all" || forecast.society === selectedSociety)
    .map((forecast) => {
      const forecastDates = getForecastDates(forecast.startDate, forecast.lenght);
      return {
        id: forecast._id,
        forecastName: forecast.name,
        lenght: forecast.lenght,
        name: forecast.name ? forecast.name : forecastDates,
        period: forecastDates,
        versions: forecast.versions,
        createdAt: forecast.createdAt,
        updatedAt: forecast.updatedAt,
        society: forecast.society
      }
    });


  const deleteForecast = async () => {
    if (token) {
      await deleteOne(token, "/forecast/", forecastId).then((res) => {
        if (res.status === 200) {
          dispatch(showSnackbar("Prévisionnel supprimé avec succès !", "success"));
          setOpenDeleteModal(false);
          getForecastsData(setForecastData, token, dispatch, setError)
        } else if (res.status === 401) {
          dispatch(sessionEnd());
        } else {
          res.json().then((err) => {
            setError(err.error);
            dispatch(showSnackbar("Erreur lors de la suppression du prévisionnel : " + err.error, "error"));
          });
        }
      });
    }
  }

  useEffect(() => {
    getForecastsData(setForecastData, token, dispatch, setError)
  }, []);


  const handleDeleteConfirmation = () => {
    setActionType("delete");
    setOpenDeleteModal(true);
  }

  const handleClose = () => {
    setActionType("close");
    setOpenDeleteModal(true);
  }

  const confirmClose = () => {
    setForecastInfo(undefined);
    setOpenCreateModal(false);
    setOpenCopyModal(false);
    setOpenDeleteModal(false);
  }

  const handleCreate = () => {
    setForecastInfo(undefined);
    setOpenCreateModal(true)
  }

  return (
    <div>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static">
          <Toolbar>
            <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1, display: { xs: "none", sm: "block" }, }}>
              Prévisionnel Budgétaire
            </Typography>
          </Toolbar>
        </AppBar>
        <Card
          elevation={0}
          component="main"
          sx={{
            backgroundColor: "white",
            m: 2,
            p: 2,
            borderRadius: "10px",
            height: "calc(100vh - 164px)",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box sx={{ display: "flex", width: "100%", justifyContent: "right", alignItems: "center", columnGap: "50px" }}>
            <FormControl sx={{ minWidth: 200 }}>
              <InputLabel sx={{ marginTop: '-10px' }} id="society-select-label">Filtrer par société</InputLabel>
              <Select
                labelId="society-select-label"
                value={selectedSociety}
                onChange={(event) => setSelectedSociety(event.target.value)}
              >
                <MenuItem value="all">Toutes les sociétés</MenuItem>
                <MenuItem value="ACTEAM-IT">ACTEAM-IT</MenuItem>
                <MenuItem value="1, 2, 3 PORTAGE">1, 2, 3 PORTAGE</MenuItem>
                <MenuItem value="ACTIMM-RE">ACTIMM-RE</MenuItem>
                <MenuItem value="ACTIMM-INV">ACTIMM-INV</MenuItem>
                <MenuItem value="MACELIA - INV">MACELIA - INV</MenuItem>
              </Select>
            </FormControl>


            <Button type="button" variant="contained" sx={{ mt: 3, mb: 2 }} onClick={handleCreate}>Créer un nouveau prévisionnel vide</Button>
            <Button
              type="button"
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              onClick={() => {
                if (rows.length === 0) {
                  dispatch(showSnackbar("Il n'existe aucun prévisionnel pour le moment.", "error"));
                } else {
                  setOpenCopyModal(true);
                }
              }}
            >
              Créer un prévisionnel par duplication d'un existant
            </Button>            </Box>

          <DataGrid
            disableRowSelectionOnClick={true}
            rows={rows}
            columns={columns}
            getRowHeight={() => 'auto'}
            onCellClick={(row) => {
              if (row.field === 'modify') {
                setForecastInfo(row.row);
                setOpenCreateModal(true);
              } else if (row.field === "delete") {
                setForecastId(row.id.toString());
                handleDeleteConfirmation();
              } else {
                navigate(`/prévisionnel/${row.id}`);
              }
            }}
            sx={{
              '& .MuiDataGrid-cell': {
                whiteSpace: 'normal', // Autorise le retour à la ligne
                wordWrap: 'break-word', // Coupe les mots si nécessaire
              },
              height: "100%"
            }}
          />
        </Card>
      </Box>

      //Update
      <Dialog
        open={openCreateModal}
        keepMounted={false}
        onClose={(event: {}, reason: "backdropClick" | "escapeKeyDown") => {
          if (reason === "backdropClick" || reason === "escapeKeyDown") {
            return;
          }
          setOpenCreateModal(false);
        }}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        fullWidth={true}
        maxWidth="sm"
        scroll="paper"
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <DialogTitle>{forecastInfo ? "Modifier" : "Ajouter"} un prévisionnel</DialogTitle>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <AddUpdateForecast setOpenModal={setOpenCreateModal} setForecastData={setForecastData} forecastInfo={forecastInfo} handleSubmit={forecastInfo ? updateForecast : addForecast} />
        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", padding: "20px" }}>
          <Button type="button" variant="contained" sx={{ mt: 3, mb: 2 }} onClick={handleClose}>Annuler</Button>
        </Box>
      </Dialog>

      //Duplicate
      <Dialog
        open={openCopyModal && rows.length > 0}
        keepMounted={false}
        onClose={(event: {}, reason: "backdropClick" | "escapeKeyDown") => {
          if (reason === "backdropClick" || reason === "escapeKeyDown") {
            return;
          }
          setOpenCopyModal(false);
        }}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        fullWidth={true}
        maxWidth="sm"
        scroll="paper"
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <DialogTitle>Dupliquer un prévisionnel existant</DialogTitle>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <DuplicateForecast
          setOpenModal={setOpenCopyModal}
          forecastData={rows}
          handleSubmit={duplicateForecast}
          setForecastData={setForecastData}
        />
        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", padding: "20px" }}>
          <Button type="button" variant="contained" sx={{ mt: 3, mb: 2 }} onClick={handleClose}>
            Annuler
          </Button>
        </Box>

      </Dialog>

      <AlerteDialog
        open={openDeleteModal}
        handleClose={() => setOpenDeleteModal(false)}
        deleteProject={actionType === "delete" ? deleteForecast : confirmClose}
        title={actionType === "delete" ? "Souhaitez vous vraiment supprimer ce prévisionnel ?" : "Confirmer l'abandon"}
        contentText={
          actionType === "delete"
            ? "Attention vous êtes sur le point de supprimer un prévisionnel ainsi que toutes ses versions et leurs données associées !"
            : "Êtes-vous sûr de vouloir abandonner les modifications sans les enregistrer ?"
        }
      />
    </div>
  );
};

export default HomeForecast;