import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import React from "react";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

/** Dialog pour afficher une alerte avec un message et deux boutons (annuler et confirmer) */
const AlerteDialog = ({
  open,
  handleClose,
  deleteProject,
  title,
  contentText,
  color = "primary",
}: {
  open: boolean;
  handleClose: any;
  deleteProject: any;
  title: string;
  contentText: string;
  color?: "primary" | "secondary" | "error" | "info" | "success" | "warning";
}) => {
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
      style={{ zIndex: 1301 }}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">{contentText}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="outlined">
          Annuler
        </Button>
        <Button onClick={deleteProject} variant="contained" color={color}>
          Confirmer
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default AlerteDialog;