import { Box, Button, Dialog, DialogTitle, IconButton, MenuItem, Select, Typography } from "@mui/material"
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/reducers";
import { useState } from "react";
import AlerteDialog from "../../AlerteDialog/AlerteDialog";
import { showSnackbar } from "../../../redux/actions";
import axios from "axios";
import { urlAPI } from "../../../config/config";
import CloseIcon from "@mui/icons-material/Close";
import TNSData from "./TNSData";
import AddUpdateTNS from "../AddUpdate/AddUpdateTNS";
import { useParams } from "react-router";
import getForecastMonthList from "../../../tools/forecast/getForecastMonthList";
import { addUpdateTNS } from "../../../tools/forecast/addUpdateTNS";
import { duplicateTNS } from "../../../tools/forecast/duplicateTNS";

const TNS = ({ forecastVersion, forecastDates }: { forecastVersion: number | undefined, forecastDates: string }) => {
    let token = useSelector((state: RootState) => {
        return state.user.token;
    });

    let dispatch = useDispatch();

    const [openModal, setOpenModal] = useState<boolean>(false);
    const [showConfirmDialog, setShowConfirmDialog] = useState<boolean>(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);

    const [selectedTNS, setSelectedTNS] = useState([]);
    const [editId, setEditId] = useState<string>("");

    const params = useParams();

    const monthList = getForecastMonthList(forecastDates);

    const confirmDelete = async () => {
        try {
            selectedTNS.forEach(async (tnsId) => {
                const response = await axios.delete(
                    `${urlAPI}/forecast/tns/${tnsId}`,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );
            })
            setShowDeleteDialog(false);
            setSelectedTNS([])
            dispatch(showSnackbar("TNS supprimée(s) avec succès.", "success"));
        } catch (error: any) {

            if (error.response) {
                console.error("Erreur API :", error.response.data);
                console.error("Statut :", error.response.status);
            } else if (error.request) {
                console.error("Erreur de requête :", error.request);
            } else {
                console.error("Erreur :", error.message);
            }
            dispatch(showSnackbar("Erreur lors de la suppression.", "error"));
        }
    }

    const handleAddUpdate = () => {
        setEditId("");
        setOpenModal(true);
    }

    const handleDuplication = () => {
        duplicateTNS(selectedTNS[0], token, setOpenModal, dispatch);
        setSelectedTNS([]);
    }

    return (
        <>
            <Typography variant="h3" sx={{ textAlign: "center", backgroundColor: "#FFD28E" }}>TNS</Typography>
            <Box sx={{ display: "flex", width: "100%", justifyContent: "right", alignItems: "center" }}>
                <Box sx={{ display: "flex", justifyContent: "right", columnGap: "50px" }}>
                    {selectedTNS.length > 0 && (
                        <Button type="button" variant="contained" sx={{ mt: 3, mb: 2, backgroundColor: "red" }} onClick={() => setShowDeleteDialog(true)}>- Supprimer</Button>
                    )}
                    {selectedTNS.length == 1 && (
                        <Button type="button" variant="contained" sx={{ mt: 3, mb: 2 }} onClick={handleDuplication}>Dupliquer le TNS</Button>
                    )}
                    <Button type="button" variant="contained" sx={{ mt: 3, mb: 2 }} onClick={handleAddUpdate}>
                        + Ajouter un frais
                    </Button>
                </Box>
            </Box>
            <TNSData
                forecastVersion={forecastVersion}
                forecastDates={forecastDates}
                selectedTNS={selectedTNS}
                setSelectedTNS={setSelectedTNS}
                openModal={openModal}
                showDeleteDialog={showDeleteDialog}
                setEditId={setEditId}
                setOpenModal={setOpenModal}
            />
            <Dialog
                open={openModal}
                keepMounted={false}
                onClose={(event: {}, reason: "backdropClick" | "escapeKeyDown") => {
                    if (reason === "backdropClick" || reason === "escapeKeyDown") {
                        return;
                    }
                    setOpenModal(false);
                }}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                fullWidth={true}
                maxWidth="xl"
                scroll="paper"
                onKeyDown={(event) => {

                }}
            >
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                    <DialogTitle>{editId ? "Modifier un TNS" : "Ajouter un TNS"}</DialogTitle>
                    <IconButton onClick={() => setShowConfirmDialog(true)}>
                        <CloseIcon />
                    </IconButton>
                </Box>

                <AddUpdateTNS
                    tnsId={editId}
                    handleSubmit={addUpdateTNS}
                    forecastId={params.id}
                    version={forecastVersion}
                    setOpenModal={setOpenModal}
                    monthList={monthList}
                    setShowConfirmDialog={setShowConfirmDialog}
                />
            </Dialog>
            <AlerteDialog
                open={showConfirmDialog}
                handleClose={() => setShowConfirmDialog(false)}
                deleteProject={() => { setShowConfirmDialog(false); setOpenModal(false); setEditId("") }}
                title="Confirmer l'abandon"
                contentText="Êtes-vous sûr de vouloir abandonner les modifications sans les enregistrer ?"
                color="error"
            />
            <AlerteDialog
                open={showDeleteDialog}
                handleClose={() => setShowDeleteDialog(false)}
                deleteProject={confirmDelete}
                title="Confirmer la suppression"
                contentText={`Vous êtes sur le point de supprimer ${selectedTNS.length} lignes. Êtes-vous sûr de vouloir supprimer les epargnes sélectionnées de façon définitive ?`}
                color="error"
            />
        </>
    )
}

export default TNS;