import { Box, Button, Checkbox, Dialog, DialogTitle, IconButton, MenuItem, Select, Typography } from "@mui/material"
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/reducers";
import { useCallback, useEffect, useMemo, useState } from "react";
import AlerteDialog from "../../AlerteDialog/AlerteDialog";
import { showSnackbar } from "../../../redux/actions";
import axios from "axios";
import { urlAPI } from "../../../config/config";
import CloseIcon from "@mui/icons-material/Close";
import { useParams } from "react-router";
import getForecastMonthList from "../../../tools/forecast/getForecastMonthList";
import SubcontractData from "./SubcontractData";
import AddUpdateSubcontract from "../AddUpdate/AddUpdateSubcontract";
import { duplicateSubcontract } from "../../../tools/forecast/duplicateSubcontract";
import { addUpdateSubcontract } from "../../../tools/forecast/addUpdateSubcontract";
import { Tooltip, Fab, } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import getSubcontractData from "../../../tools/forecast/getSubcontractData";
import { ISubcontract } from "../../../interface/subcontract";


const Subcontract = ({ forecastVersion, forecastDates }: { forecastVersion: number | undefined, forecastDates: string }) => {
    let token = useSelector((state: RootState) => {
        return state.user.token;
    });

    let dispatch = useDispatch();

    const [openModal, setOpenModal] = useState<boolean>(false);
    const [showConfirmDialog, setShowConfirmDialog] = useState<boolean>(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);

    const [subcontractData, setSubcontractData] = useState<ISubcontract[]>([]);

    const [agency, setAgency] = useState<string | null>("All");
    const agencies = [
        { label: "Toutes les agences", value: "All" },
        { label: "Agence Nord", value: "Nord" },
        { label: "Agence Sud", value: "Sud" }
    ];

    const [activeTypes, setActiveTypes] = useState<string[]>(["Tout"]);

    const headers = ["Tout", "Forfait", "Régie"]; // Types de frais possibles


    const [selectedSubcontract, setSelectedSubcontract] = useState([]);
    const [editId, setEditId] = useState<string>("");
    const [error, setError] = useState("");

    const params = useParams();

    const monthList = getForecastMonthList(forecastDates);


    const confirmDelete = useCallback(async () => {
        try {
            await Promise.all(
                selectedSubcontract.map(async (subcontractId) => {
                    await axios.delete(`${urlAPI}/forecast/subcontract/${subcontractId}`, {
                        headers: { Authorization: `Bearer ${token}` },
                    });
                })
            );

            setShowDeleteDialog(false);
            setSelectedSubcontract([]); // Reset en une seule fois
            dispatch(showSnackbar("Sous-traitance(s) supprimée(s) avec succès.", "success"));
        } catch (error: any) {
            console.error("Erreur API :", error.response?.data || error.message);
            dispatch(showSnackbar("Erreur lors de la suppression.", "error"));
        }
    }, [selectedSubcontract, token, dispatch]);


    const handleAddUpdate = () => {
        setEditId("");
        setOpenModal(true);
    }

    const handleDuplication = useCallback(() => {
        duplicateSubcontract(selectedSubcontract[0], token, setOpenModal, dispatch);
        setSelectedSubcontract([]);
    }, [selectedSubcontract, token, dispatch, setOpenModal]);
    

    useEffect(() => {
        getSubcontractData(params.id, forecastVersion, agency, setSubcontractData, token, dispatch, setError)
            .catch(error => {
                console.error("Erreur lors du chargement des sous-traitances", error);
                dispatch(showSnackbar("Erreur lors du chargement des sous-traitances.", "error"));
            });

    }, [forecastVersion, agency, openModal, showDeleteDialog, handleDuplication]);

    return (
        <>
            <Typography variant="h3" sx={{ textAlign: "center", backgroundColor: "#FFD28E" }}>Sous-Traitance</Typography>
            <Box sx={{ display: "flex", width: "100%", justifyContent: "right", alignItems: "center" }}>
                <Select
                    labelId="agency"
                    id="agency"
                    value={agency}
                    label="Agence"
                    onChange={(event) => { setAgency(event.target.value) }}
                    sx={{ width: "200px", height: "50px" }}
                >
                    {agencies.map((opt, index) => (
                        <MenuItem key={index} value={opt.value}>
                            {opt.label}
                        </MenuItem>
                    ))}
                </Select>
                <Select
                    labelId="subcontractType"
                    id="subcontractType"
                    value={activeTypes}
                    label="Type de frais"
                    onChange={(event: any) => {
                        const newValue = event.target.value;

                        if (newValue.includes("Tout")) {
                            setActiveTypes(["Tout"]);
                        } else {
                            setActiveTypes(newValue.filter((val: string) => val !== "Tout"));
                        }
                    }}
                    multiple
                    sx={{ width: "200px", height: "50px", ml: 2 }}
                    renderValue={(selected) => selected.join(", ")}
                >
                    {headers.map((opt, index) => (
                        <MenuItem key={index} value={opt}>
                            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                                <Box>{opt}</Box> <Checkbox checked={activeTypes.includes(opt)} />
                            </Box>
                        </MenuItem>
                    ))}
                </Select>


                <Box sx={{ display: "flex", width: "100%", justifyContent: "right", alignItems: "center" }}>
                    {selectedSubcontract.length > 0 && (
                        <Button type="button" variant="contained" sx={{ ml: 3, mt: 3, mb: 2, backgroundColor: "red" }} onClick={() => setShowDeleteDialog(true)}>Supprimer</Button>
                    )}
                    {selectedSubcontract.length == 1 && (
                        <Button type="button" variant="contained" sx={{ ml: 3, mt: 3, mb: 2 }} onClick={handleDuplication}>Dupliquer la sous-traitance</Button>
                    )}
                    <Button type="button" variant="contained" sx={{ ml: 3, mt: 3, mb: 2 }} onClick={handleAddUpdate}>
                        + Ajouter une sous-traitance
                    </Button>
                </Box>
                <Box
                    sx={{
                        position: "fixed",
                        bottom: 15,
                        right: 20,
                        display: "flex",
                        alignItems: "center",
                        gap: 2,
                        backgroundColor: "rgba(255, 255, 255, 0.95)",
                        padding: "10px 20px",
                        borderRadius: "50px",
                        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
                        zIndex: 1100,
                    }}
                >
                    <Typography
                        variant="subtitle2"
                        sx={{
                            fontWeight: "bold",
                            color: "#333",
                            marginRight: "10px",
                        }}
                    >
                        Sous-Traitance
                    </Typography>

                    {selectedSubcontract.length > 0 && (
                        <Tooltip title="Supprimer">
                            <Fab color="error" size="small" onClick={() => setShowDeleteDialog(true)}>
                                <DeleteIcon />
                            </Fab>
                        </Tooltip>
                    )}

                    {selectedSubcontract.length === 1 && (
                        <Tooltip title="Dupliquer">
                            <Fab color="primary" size="small" onClick={handleDuplication}>
                                <ContentCopyIcon />
                            </Fab>
                        </Tooltip>
                    )}

                    <Tooltip title="Ajouter">
                        <Fab color="success" size="small" onClick={handleAddUpdate}>
                            <AddCircleIcon />
                        </Fab>
                    </Tooltip>
                </Box>
            </Box>
            {
                (activeTypes.includes("Tout") ? headers.slice(1) : activeTypes).map((type) => {

                    return (
                        <SubcontractData
                            key={type}
                            forecastVersion={forecastVersion}
                            forecastDates={forecastDates}
                            type={type}
                            agency={agency}
                            selectedSubcontract={selectedSubcontract}
                            setSelectedSubcontract={setSelectedSubcontract}
                            openModal={openModal}
                            showDeleteDialog={showDeleteDialog}
                            setEditId={setEditId}
                            setOpenModal={setOpenModal}
                            subcontracts={subcontractData
                                .map(sub => ({
                                    ...sub,
                                    typeFrais: sub.typeFrais || "Forfait" // Assigner "Forfait" si typeFrais est null ou undefined
                                }))
                                .filter(sub => sub.typeFrais === type)}
                        />
                    )
                })
            }




            <Dialog
                open={openModal}
                keepMounted={false}
                onClose={(event: {}, reason: "backdropClick" | "escapeKeyDown") => {
                    if (reason === "backdropClick" || reason === "escapeKeyDown") {
                        return;
                    }
                    setOpenModal(false);
                }}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                fullWidth={true}
                maxWidth="xl"
                scroll="paper"
                onKeyDown={(event) => {

                }}
            >
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                    <DialogTitle>{editId ? "Modifier la sous-traitance" : "+ Ajouter une sous-traitance"}</DialogTitle>
                    <IconButton onClick={() => setShowConfirmDialog(true)}>
                        <CloseIcon />
                    </IconButton>
                </Box>

                <AddUpdateSubcontract
                    subcontractId={editId}
                    handleSubmit={addUpdateSubcontract}
                    forecastId={params.id}
                    version={forecastVersion}
                    setOpenModal={setOpenModal}
                    monthList={monthList}
                    setShowConfirmDialog={setShowConfirmDialog}
                />
            </Dialog>
            <AlerteDialog
                open={showConfirmDialog}
                handleClose={() => setShowConfirmDialog(false)}
                deleteProject={() => { setShowConfirmDialog(false); setOpenModal(false); setEditId("") }}
                title="Confirmer l'abandon"
                contentText="Êtes-vous sûr de vouloir abandonner les modifications sans les enregistrer ?"
                color="error"
            />
            <AlerteDialog
                open={showDeleteDialog}
                handleClose={() => setShowDeleteDialog(false)}
                deleteProject={confirmDelete}
                title="Confirmer la suppression"
                contentText={`Vous êtes sur le point de supprimer ${selectedSubcontract.length} lignes. Êtes-vous sûr de vouloir supprimer les sous-traitance sélectionnées de façon définitive ?`}
                color="error"
            />
        </>
    )
}

export default Subcontract;