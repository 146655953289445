import { DataGrid, getGridStringOperators, GridColDef, GridRowsProp } from "@mui/x-data-grid";
import { Box, Button, InputAdornment, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { IBossSalary } from "../../../interface/bossSalary";
import getBossSalary from "../../../tools/forecast/getBossSalary";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/reducers";
import { updateBossSalary } from "../../../tools/forecast/updateBossSalary";

const AnnualForecastTotalData = ({ forecastVersion, forecast, totalBySection }: { forecastVersion?: number, forecast?: string, totalBySection: any }) => {
    let dispatch = useDispatch();

    let token = useSelector((state: RootState) => state.user.token) as string;

    const [bossSalaryAmount, setBossSalaryAmount] = useState(0);
    const [bossSalary, setBossSalary] = useState<IBossSalary>();
    const [error, setError] = useState('');

    const params = useParams();

    useEffect(() => {
        getBossSalary(params.id, forecastVersion, setBossSalary, token, dispatch, setError);
    }, [forecastVersion])

    useEffect(() => {
        if (bossSalary) setBossSalaryAmount(bossSalary.amount);
    }, [bossSalary])

    const handleUpdate = () => {
        updateBossSalary(token, bossSalaryAmount, dispatch, setError, params.id, forecastVersion);
    }

    const handleBossSalary = (event: React.ChangeEvent<HTMLInputElement>) => {
        let newAmount = parseFloat(event.target.value);
        newAmount = parseFloat(newAmount.toFixed(2));
        setBossSalaryAmount(newAmount);
    }

    const columnsInfos: GridColDef[] = [{
        field: "field",
        headerName: "",
        filterable: true,
        minWidth: 599,
    }]

    const columnsMonth: GridColDef[] = [{
        field: "forecastData",
        headerName: forecast,
        filterable: true,
        minWidth: 599,
        align: "center",
        headerAlign: 'center',
        renderCell: (params: any) => {
            if (params.row.id === 'bossSalary') {
                return (
                    <Box sx={{ display: "flex", alignItems: "center", columnGap: "50px" }}>
                        <TextField
                            margin="normal"
                            type="number"
                            inputProps={{
                                step: "0.1",
                                inputMode: "decimal"
                            }}
                            label="Rémunération du gérant"
                            value={bossSalaryAmount}
                            onChange={handleBossSalary}
                            autoFocus
                            InputProps={{
                                endAdornment: <InputAdornment position="end">€</InputAdornment>,
                            }}
                        />
                        <Button type="button" variant="contained" sx={{ mt: 3, mb: 2 }} onClick={handleUpdate}>
                            Enregistrer
                        </Button>
                    </Box>
                );
            }
            return <div>{params.value}</div>;  // Retourner la valeur standard si ce n'est pas la ligne 'bossSalary'
        },
    }]

    const columns: GridColDef[] = [...columnsInfos, ...columnsMonth];

    const totalFields = {
        "Chiffre d'Affaires": ["CA - Régie", "CA - Forfait", "CA - Hébergement", "CA - TMA/TME", "CA - TRA"],
        "Dépenses": ["Salariés", "Epargne", "TNS", "Investissements", "Frais de fonctionnement", "Sous-traitance", "DGFIP", "Entreprise"]
    }

    const totalCA = totalFields["Chiffre d'Affaires"].reduce((acc: any, key) => {
        const sumForKey = Object.values(totalBySection[key]).reduce((sum: any, value: any) => sum + value, 0);
        acc[key] = sumForKey;
        acc.total += sumForKey;
        return acc;
    }, { total: 0 }).total

    const totalDepenses = totalFields["Dépenses"].reduce((acc: any, key) => {
        const sumForKey = Object.values(totalBySection[key]).reduce((sum: any, value: any) => sum + value, 0);
        acc[key] = sumForKey;
        acc.total += sumForKey;
        return acc;
    }, { total: 0 }).total

    const beforeISAmount = totalCA - totalDepenses - (bossSalaryAmount * 1.5 || 0);

    const amountIS = totalCA > 42500 ? (42500 * 0.15 + (totalCA - 42500) * 0.25) : totalCA * 0.15

    const totalRows = [
        { id: "CA", field: "Chiffre d'Affaires", data: totalCA },
        { id: "dépenses", field: "Dépenses", data: " - " + totalDepenses },
        { id: "beforeBossSalary", field: "Avant rémunération du gérant", data: totalCA - totalDepenses },
        { id: "bossSalary", field: "Rémunération du gérant", data: "" },
        { id: "socialCharges", field: "Charges sociales", data: " - " + (bossSalaryAmount / 2 || 0) },
        { id: "beforeIS", field: "Avant impôt sur la société", data: beforeISAmount },
        { id: "typeIS", field: "Type impôt sur la société", data: "1" },
        { id: "IS", field: "Impôt sur la société", data: " - " + amountIS },
        { id: "profits", field: "Bénéfices", data: beforeISAmount - amountIS }
    ]

    const rows: GridRowsProp = totalRows.map((row) => {
        return {
            id: row.id,
            field: row.field,
            forecastData: row.data + (row.id !== "typeIS" ? " €" : "")
        }
    })


    return (
        <>
            <Typography variant="h3" sx={{ textAlign: "center", backgroundColor: "#FFD28E" }}>Total prévisionnel</Typography>
            <DataGrid
                disableRowSelectionOnClick={true}
                rows={rows}
                columns={columns}
                getRowHeight={() => 70}
                hideFooter
                sx={{
                    width: "1200px",
                    margin: "auto",
                    fontSize: "1.5em",
                    textAlign: "center"
                }}
            />
        </>
    )
}

export default AnnualForecastTotalData;