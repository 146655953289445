// React
import { useState } from "react";

// Redux
import { useDispatch } from "react-redux";
import { showSnackbar } from "../../redux/actions/index";

// Import MaterialUI
import { Box } from "@mui/system";
import { Container, Card } from "@mui/material";
import styled from "@emotion/styled";

//Logo
import logo from "../../img/logo/Kairos-removebg-preview.png";
import logoAzur from "../../img/azure_64.webp";

// Composants
import LoginLocal from "../../components/Login/LoginLocal";
import Signup from "../../components/Sign/Signup";

// React router dom
import { useLocation, useNavigate } from "react-router-dom";

// Config
import { colorOrange } from "../../config/color";

const Login = () => {
  const [gotAccount, setGotAccount] = useState(true);
  const [error, setError] = useState<string>("");

  const dispatch = useDispatch();

  const setOpenSuccess = (value: boolean) => {
    dispatch(showSnackbar("Compte créé avec succès", "success"));
  };

  const location = useLocation();
  const navigate = useNavigate();

  const StyledP = styled("p")`
    &:hover {
      color: ${colorOrange};
    }
    cursor: pointer;
    margin-bottom: 2rem;
  `;

  return (
    <div className="Login">
      <Container
        component="main"
        maxWidth="xs"
        sx={{
          padding: { xs: 0, lg: 2 },
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
        }}
      >
        <Card
          component="main"
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            paddingX: 5,
            paddingY: 6,
            borderRadius: "10px",
            height: "fit-content",
          }}
        >
          <Box
            component="img"
            sx={{
              height: 100,
              width: 80,
              marginBottom: 2,
            }}
            alt="logo Kairos"
            src={logo}
            className="logo-kairos"
          />
          {gotAccount ? (
            <>
              <LoginLocal setError={setError} error={error} />
            </>
          ) : (
            <Signup error={error} setError={setError} setOpenSuccess={setOpenSuccess} setGotAccount={setGotAccount} />
          )}
          {!gotAccount && (
            <StyledP
              onClick={() => {
                setGotAccount(true);
                setError("");
              }}
            >
              J'ai déjà un compte !
            </StyledP>
          )}

          {gotAccount && (
            <div
              className="btn-azur"
              onClick={() =>
                navigate("/azureauth", {
                  state: {
                    from: (location.state as { from: object })?.from,
                  },
                })
              }
              style={{
                borderRadius: "6px",
              }}
            >
              <span>
                <img src={logoAzur} alt="logo Azure" />
                Se connecter avec Azure 
              </span>
            </div>
          )}
        </Card>
      </Container>
    </div>
  );
};

export default Login;
