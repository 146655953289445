import axios from "axios";
import { urlAPI } from "../../config/config";
import { showSnackbar } from "../../redux/actions";
import { ISalaryMatriceFormValues } from "../../interface/salaryMatrice";

export const addUpdateSalarialMatrice = async (
    token: string,
    values: ISalaryMatriceFormValues,
    forecastId: string,
    version: number,
    setOpenModal: Function,
    dispatch: Function,
    setError: Function
) => {
    try {
        const response = await axios.post(`${urlAPI}/forecast/salarialMatrice/addUpdate`,
          { ...values, forecastId, forecastVersion: version },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );       
        setOpenModal(false);
        dispatch(showSnackbar("Matrice salariale enregistrée avec succès.", "success"));
      } catch (error: any) {
        if (error.response) {
          console.error("Erreur API :", error.response.data);
          console.error("Statut :", error.response.status);
        } else if (error.request) {
          console.error("Erreur de requête :", error.request);
        } else {
          console.error("Erreur :", error.message);
        }
  
        dispatch(showSnackbar("Erreur lors de l'enregistrement.", "error"));
      }
};
