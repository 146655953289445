import { Button } from "@mui/material";
import { Box } from "@mui/system";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/reducers";
import { useEffect, useState } from "react";
import axios from "axios";
import { urlAPI } from "../../../config/config";
import { ISubcontract, ISubcontractFormValues } from "../../../interface/subcontract";
import SubcontractInfosFields from "./SubcontractInfosFields";
import SubcontractMonthFields from "./SubcontractMonthFields";
import { convertMonthsToOldFormat } from "../../function/convertToOldFormat/convertToOldFormat";

const validationSchema = yup.object({
  label: yup.string().required("Le libellé est obligatoire"),
  agency: yup.string(),
  supplier: yup.string().required("Le fournisseur est obligatoire")
});

const AddUpdateSubcontract = ({
  subcontractId,
  handleSubmit,
  forecastId,
  version,
  setOpenModal,
  monthList,
  setShowConfirmDialog
}: {
  subcontractId?: string;
  handleSubmit: Function;
  forecastId?: string,
  version?: number,
  setOpenModal: Function,
  monthList: { name: string, field: string }[],
  setShowConfirmDialog: Function
}) => {
  let dispatch = useDispatch();

  let token = useSelector((state: RootState) => state.user.token) as string;

  const [subcontract, setSubcontract] = useState<ISubcontract>();

  const getSubcontract = async () => {
    if (!token || !subcontractId) {
      return;
    }
    try {
      const syncResponse = await axios.get(`${urlAPI}/forecast/subcontract/${subcontractId}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      const updatedMessageFormated = convertMonthsToOldFormat(syncResponse.data.message);

      setSubcontract(updatedMessageFormated);
    } catch (error: any) {
      const errorMessage = error.message || error.response?.data?.message || "Erreur lors de la mise à jour des salariés.";
      setError(errorMessage);
      setTimeout(() => setError(""), 4000);
    }
  }

  useEffect(() => {
    getSubcontract();
  }, []);

  const [error, setError] = useState("");

  const formik = useFormik({
    initialValues: {
      label: subcontract ? subcontract.label : "",
      agency: subcontract ? subcontract.agency : "Toutes",
      supplier: subcontract ? subcontract.supplier._id : "",
      typeFrais: subcontract ? subcontract.typeFrais : "", // Ajout du champ typeFrais
      months: subcontract ? subcontract.months
        : monthList.reduce((acc: any, item: any) => {
          acc[item.field] = 0;
          return acc;
        }, {}),
    },
    validationSchema: validationSchema,
    onSubmit: (values: ISubcontractFormValues) => {
      handleSubmit(token, values, forecastId, version!, setOpenModal, dispatch, setError, subcontractId);
    },
    enableReinitialize: true,
  });
  

  return (
    <Box component="form" onSubmit={formik.handleSubmit} sx={{ mt: 1, width: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "center", padding: "20px" }}>
      <Box sx={{ display: "flex", justifyContent: "space-between", minHeight: "600px" }}>
        <SubcontractInfosFields formik={formik} />
        <SubcontractMonthFields formik={formik} monthList={monthList} />
      </Box>
      <Box sx={{ display: "flex", columnGap: "200px" }}>
        <Button type="button" variant="contained" sx={{ mt: 3, mb: 2 }} onClick={() => { setShowConfirmDialog(true) }}>Annuler</Button>
        <Button type="submit" variant="contained" sx={{ mt: 3, mb: 2 }}>Enregistrer</Button>
      </Box>
    </Box>
  )
}

export default AddUpdateSubcontract;