import { Autocomplete, Switch, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";

const TurnoverCollabField = ({
    formik, 
    salaries,
    value,
    setTjm
} : {
    formik: any, 
    salaries : any,
    value : any,
    setTjm : Function
}) => {
    const label = { inputProps: { 'aria-label': 'Switch demo' } };
    
    const [fictionCollab, setFictionCollab] = useState<boolean>(false);

    return (
        <>
            <Box sx={{display : "flex", justifyContent: "center"}}>
                <Typography variant="h6" sx={{textAlign: "center", fontWeight : !fictionCollab ? "bold" : ""}}>Collaborateur réel</Typography>
                <Switch 
                    {...label} 
                    onClick={() => {
                    setFictionCollab(!fictionCollab)
                    if(fictionCollab){
                        formik.setFieldValue("fictifCollab", "");
                    }
                    else{
                        formik.setFieldValue("collab", "");
                    }
                    }} 
                    checked={fictionCollab} 
                /> 
                <Typography variant="h6" sx={{textAlign: "center", fontWeight : fictionCollab ? "bold" : ""}}>Collaborateur fictif</Typography>
            </Box>
            {fictionCollab && (<TextField
                margin="normal"
                required
                type="text"
                id="fictifCollab"
                label="Collaborateur fictif"
                name="fictifCollab"
                autoComplete="fictifCollab"
                value={formik.values.fictifCollab}
                onChange={formik.handleChange}
                autoFocus
            />)}
            {!fictionCollab && (
                <Autocomplete
                    key="collab"
                    id="collab"
                    options={salaries}
                    getOptionLabel={(option: any) => `${option.firstname || ""} ${option.lastname || ""}`.trim()}
                    isOptionEqualToValue={(option: any, value: any) => option._id === value?._id}
                    renderOption={(props, option: any) =>
                        <li {...props} data-id_dolibarr={option._id} key={option._id}>
                            {`${option.firstname || ""} ${option.lastname || ""}`.trim()}
                        </li>
                    }
                    value={value}
                    onChange={(event, value: any) => {
                        if(value){
                            formik.setFieldValue("collab", value._id);
                            setTjm(value.tjm)
                        }
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            required
                            name="collab"
                            margin="normal"
                            label="Collaborateur réel"
                        />
                    )}
                />
            )

            }
        </>
    )
}

export default TurnoverCollabField;