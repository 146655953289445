import { Alert, Autocomplete, Button, CircularProgress, Switch, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { colorOrange } from "../../../config/color";
import { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { urlAPI } from "../../../config/config";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/reducers";
import { sessionEnd } from "../../../redux/actions";
import { IClient } from "../../../interface/client";
import { getAll } from "../../../fetchs/get";
import { ISubcontract } from "../../../interface/subcontract";

const SubcontractInfosFields = ({
  formik
} : {
  formik: any
}) => {
  const dispatch = useDispatch();
  const token = useSelector((state: RootState) => state.user.token);

  const [message, setMessage] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [suppliers, setSuppliers] = useState<IClient[]>([]);
  
  const [loading, setLoading] = useState<boolean>(false);

  const getAllSuppliers = useCallback(() => {
    if (token) {
      getAll(token, "/suppliers/", setSuppliers, () => dispatch(sessionEnd()), setError);
    }
  }, []);

  const supplierActeam = suppliers.map((client: IClient) => ({
    _id: client._id,
    name: client.name,
  })) as unknown as readonly string[];
  

  useEffect(() => {
    getAllSuppliers();
  }, [getAllSuppliers]);

  const selectInputs = [
    { id: "agency", options: ["Toutes", "Nord", "Sud"], value: formik.values.agency || null, label: "Agence", touched: formik.touched.agency, errors: formik.errors.agency, required: false },
    { id: "supplier", options: supplierActeam, value: supplierActeam.find((supplier: any) => supplier._id === formik.values.supplier ) || null, label: "Fournisseur", touched: formik.touched.supplier, errors: formik.errors.supplier, required: true },
    { id: "typeFrais", options: ["Forfait", "Régie"], value: formik.values.typeFrais || null, label: "Type de frais", touched: formik.touched.typeFrais, errors: formik.errors.typeFrais, required: true },
  ];
  

  const handleFetch = async () => {
    setMessage("");
    setError("");
    setLoading(true);
    try {
      const syncResponse = await axios.get(`${urlAPI}/suppliers/dolibarr`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      setMessage(syncResponse.data.message);
      setTimeout(() => setMessage(""), 4000);

      if (syncResponse.status === 200) {
        await getAllSuppliers();
      }
    } catch (error: any) {
      const errorMessage = error.message || error.response?.data?.message || "Erreur lors de la mise à jour des fournisseurs.";
      setError(errorMessage);
      setTimeout(() => setError(""), 4000);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", width: "30%" }}>
            <TextField
                margin="normal"
                required
                type="text"
                id="label"
                label="Libellé du frais"
                name="label"
                autoComplete="label"
                value={formik.values.label}
                onChange={formik.handleChange}
                error={formik.touched.label && Boolean(formik.errors.label)}
                helperText={formik.touched.label && formik.errors.label}
                autoFocus
            />
        {
            selectInputs.map((select) => {
              return (
                <Autocomplete
                    key={select.id}
                    id={select.id}
                    options={select.options}
                    getOptionLabel={(option: any) =>select.id === "supplier" ? `${option.name || ""}`.trim() : option }
                    isOptionEqualToValue={(option: any, value: any) => select.id === "supplier" ? option._id === value?._id : option === value}
                    value={select.value}
                    onChange={(event, value: any) => {
                        formik.setFieldValue(
                          select.id,
                          value ? (select.id === "supplier" ? value._id : value) : ""
                        );
                    }}
                    renderInput={(params) => (
                        <TextField
                          {...params}
                          required={select.required}
                          name={select.id}
                          margin="normal"
                          label={select.label}
                          error={select.touched && Boolean(select.errors)}
                          helperText={select.touched && select.errors}
                        />
                    )}
                  />
              )
            })
        }
        {error && <Alert severity="error">{error}</Alert>}
        {message && <Alert severity="success">{message}</Alert>}
        {loading ? (
            <CircularProgress sx={{ margin: "16px auto" }} />
        ) : (
            <>
                <Button
                    onClick={handleFetch}
                    fullWidth
                    sx={{ mt: 3, mb: 2, border: `1px solid ${colorOrange}` }}
                >
                    {"Mettre à jour la liste des fournisseurs"}
                </Button>
            </>
        )}
        </Box>
    </>
  )
}

export default SubcontractInfosFields;