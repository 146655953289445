import { Box } from "@mui/system";
import {TextField } from "@mui/material";
import { ICompany } from "../../../interface/company";

const CompanyLabelField = ({
  company,
  formik,
}: {
  company?: ICompany,
  formik: any
}) => {
  return (
    <>
        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", width: "30%" }}>
          {!company ? (
            <>
              <TextField
                margin="normal"
                required
                type="text"
                id="customLabel"
                label="Libellé du frais d'entreprise"
                name="label"
                autoComplete="label"
                value={formik.values.label}
                onChange={formik.handleChange}
                autoFocus
              />
            </>
            ) : (
              <TextField
                margin="normal"
                required
                type="text"
                id="customLabel"
                label="Libellé de l'épargne"
                name="label"
                autoComplete="label"
                value={formik.values.label}
                onChange={formik.handleChange}
                autoFocus
              />
            )}
        </Box>
    </>
  )
}

export default CompanyLabelField;