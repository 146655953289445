import axios from "axios";
import { urlAPI } from "../../config/config";
import { showSnackbar } from "../../redux/actions";
import { ICompanyFormValues } from "../../interface/company";

export const updateBossSalary = async (
    token: string,
    newAmount: number,
    dispatch: Function,
    setError: Function,
    forecastId?: string,
    version?: number,
) => {
    try {
        const response = await axios.post(`${urlAPI}/forecast/bossSalary/update`,
            { newAmount, forecastId, forecastVersion: version },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
        );     
        dispatch(showSnackbar("Rémunération du gérant mis à jour avec succés.", "success"));
      } catch (error: any) {
        if (error.response) {
          console.error("Erreur API :", error.response.data);
          console.error("Statut :", error.response.status);
        } else if (error.request) {
          console.error("Erreur de requête :", error.request);
        } else {
          console.error("Erreur :", error.message);
        }
  
        setError("Impossible d'enregistrer l'épargne'. Veuillez réessayer.");
        dispatch(showSnackbar("Erreur lors de l'enregistrement.", "error"));
      }
};