import getBusinessDaysForMonth from "../getBusinessDays";

const monthsMap : Record<string, { number: number; field: string }> = {
    Janvier: { number: 0, field: "january" },
    Février: { number: 1, field: "february" },
    Mars: { number: 2, field: "march" },
    Avril: { number: 3, field: "april" },
    Mai: { number: 4, field: "may" },
    Juin: { number: 5, field: "june" },
    Juillet: { number: 6, field: "july" },
    Août: { number: 7, field: "august" },
    Septembre: { number: 8, field: "september" },
    Octobre: { number: 9, field: "october" },
    Novembre: { number: 10, field: "november" },
    Décembre: { number: 11, field: "december" },
};
  
export default function getForecastMonthList(forecastDates: string) {
  if (!forecastDates || typeof forecastDates !== "string") {
      throw new Error("forecastDates doit être une chaîne valide au format 'Mois Année / Mois Année'");
  }

  const [start, end] = forecastDates.split("/").map(date => date.trim());

  // Extraire le mois et l'année pour la date de début et de fin
  const [startMonthName, startYear] = start.split(" ");
  const [endMonthName, endYear] = end.split(" ");

  if (!startMonthName || !startYear || !endMonthName || !endYear) {
      throw new Error("forecastDates est mal formaté. Attendu: 'Mois Année / Mois Année'");
  }

  const startMonth = monthsMap[startMonthName]?.number;
  const endMonth = monthsMap[endMonthName]?.number;

  if (startMonth === undefined || endMonth === undefined) {
      throw new Error("Un ou plusieurs mois dans forecastDates sont invalides.");
  }

  const result = [];
  let currentYear = parseInt(startYear, 10);
  let currentMonth = startMonth;

  while (currentYear < parseInt(endYear, 10) || (currentYear === parseInt(endYear, 10) && currentMonth <= endMonth)) {
      const monthName = Object.keys(monthsMap).find(
          key => monthsMap[key].number === currentMonth
      );
      const fieldName = monthsMap[monthName!].field;

      result.push({
        name: monthName ?  monthName.charAt(0).toUpperCase() + monthName.slice(1) + " "+ currentYear : "",
        field: fieldName+currentYear
      });

      // Passer au mois suivant
      currentMonth++;
      if (currentMonth > 11) {
          currentMonth = 0;
          currentYear++;
      }
  }

  return result;
}
