import { getAll} from "../../fetchs/get";
import { sessionEnd } from "../../redux/actions";

export default async function getTurnoverData (
    forecastId : string | undefined,
    forecastVersion: number | undefined,
    agency: string | null,
    type: string,
    setTurnoverData : Function,
    token : string | undefined,
    dispatch : Function,
    setError : Function
) {
    const types = {
        "Régie" : "management",
        "Forfait" : "package",
        "Hébergement" : "hosting",
        "TMA/TME" : "TMA-TME",
        "TRA" : "TRA",
    }
    if(token){
        getAll(token, '/forecast/'+forecastId+"/"+forecastVersion+'/turnover/'+types[type as keyof typeof types]+"/"+agency, setTurnoverData, 
            () => {
                dispatch(sessionEnd());
            }, setError
        )
    }
}