import getForecastMonthList from "../../../tools/forecast/getForecastMonthList";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/reducers";
import ForecastTotalData from "./ForecastTotalData";

const ForecastTotal = ({ forecastDates } : {forecastDates: string }) => {
    const monthList = getForecastMonthList(forecastDates);

    const totalBySection = useSelector((state: RootState) => ({
        "CA - Régie": state.forecast.totals["Régie" as keyof typeof state.forecast.totals] || {},
        "CA - Forfait": state.forecast.totals["Forfait" as keyof typeof state.forecast.totals] || {},
        "CA - Hébergement": state.forecast.totals["Hébergement" as keyof typeof state.forecast.totals] || {},
        "CA - TMA/TME": state.forecast.totals["TMA/TME" as keyof typeof state.forecast.totals] || {},
        "CA - TRA": state.forecast.totals["TRA" as keyof typeof state.forecast.totals] || {},
        "Salariés": state.forecast.totals["Salariés" as keyof typeof state.forecast.totals] || {},
        "TNS": state.forecast.totals["TNS" as keyof typeof state.forecast.totals] || {},
        "Frais de fonctionnement": state.forecast.totals["Frais de fonctionnement" as keyof typeof state.forecast.totals] || {},
        "Investissements": state.forecast.totals["Investissements" as keyof typeof state.forecast.totals] || {},
        "Entreprise": state.forecast.totals["Entreprise" as keyof typeof state.forecast.totals] || {},
        "Sous-traitance": state.forecast.totals["Sous-traitance" as keyof typeof state.forecast.totals] || {},
        "DGFIP": state.forecast.totals["DGFIP" as keyof typeof state.forecast.totals] || {},
    }));

    return <ForecastTotalData monthList={monthList} totalBySection={totalBySection} />
}

export default ForecastTotal;