import { combineReducers } from "redux";
import user from "./user";
import lotSynthesis from "./lotSyntesis";
import snackbarReducer from "./snackbar";
import { forecastReducer } from "./forecastReducer";

const rootReducer = combineReducers({ user: user, lotSynthesis: lotSynthesis, snackbar: snackbarReducer, forecast : forecastReducer });

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
