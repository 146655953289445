import { Box } from "@mui/system";
import { ITNS } from "../../../interface/tns";
import { Autocomplete, Switch, TextField, Typography } from "@mui/material";
import { useState } from "react";

const TNSLabelField = ({
  tns,
  formik,
}: {
  tns?: ITNS,
  formik: any
}) => {
  const label = { inputProps: { 'aria-label': 'Switch demo' } };

  const [fixedLabel, setFixedLabel] = useState<boolean>(false);

  return (
    <>
        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", width: "30%" }}>
          {!tns ? (
            <>
              <Box sx={{display : "flex", justifyContent: "center"}}>
                <Typography variant="h6" sx={{textAlign: "center", fontWeight : !fixedLabel ? "bold" : ""}}>Frais courant</Typography>
                <Switch 
                    {...label} 
                    onClick={() => {
                      formik.setFieldValue("label", "");
                      setFixedLabel(!fixedLabel)
                    }} 
                    checked={fixedLabel} 
                /> 
                <Typography variant="h6" sx={{textAlign: "center", fontWeight : fixedLabel ? "bold" : ""}}>Frais libre</Typography>
              </Box>
              {fixedLabel && (<TextField
                margin="normal"
                required
                type="text"
                id="customLabel"
                label="Frais Libre"
                name="label"
                autoComplete="label"
                value={formik.values.label}
                onChange={formik.handleChange}
                autoFocus
              />)}
              {!fixedLabel && (<Autocomplete
                key="label"
                id="fixedLabel"
                options={["Complémentaire Santé","Retraite Madelin","Prévoyance","URSAFF","PEE - PERCO Dirigeant","PEE - PERCO Dirigeant - Cotisations Sociales","PEE - PERCO - Frais Annuels"]}
                value={formik.values.label || null}
                isOptionEqualToValue={(option, value) => option === value}
                onChange={(event, value) => formik.setFieldValue("label", value || "")}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    name="label"
                    margin="normal"
                    label="Frais Courants"
                  />
                )}
              />)}
            </>
            ) : (
              <TextField
                margin="normal"
                required
                type="text"
                id="customLabel"
                label="Libellé du TNS"
                name="label"
                autoComplete="label"
                value={formik.values.label}
                onChange={formik.handleChange}
                autoFocus
              />
            )}
        </Box>
    </>
  )
}

export default TNSLabelField;