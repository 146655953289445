import { Button, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { useFormik } from "formik";
import * as yup from "yup";
import { IForecastFormValues } from "../../../interface/forecast";
import { useNavigate } from "react-router";
import { fetchPostProtect } from "../../../fetchs/post";
import { sessionEnd, showSnackbar } from "../../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/reducers";
import { useState } from "react";
import getForecastsData from "../../../tools/forecast/getForecastsData";


const AddUpdateForecast = ({
    setOpenModal,
    setForecastData,
    forecastInfo,
    handleSubmit
}: {
    setOpenModal: Function,
    setForecastData: Function,
    forecastInfo: any,
    handleSubmit: Function
}) => {
    let navigate = useNavigate();
    let dispatch = useDispatch();
    const [error, setError] = useState('');
    const token = useSelector((state: RootState) => {
        return state.user.token;
    });

    const defaultDate = new Date();
    const defaultMonthYear = `${defaultDate.getFullYear()}-${(defaultDate.getMonth() + 1).toString().padStart(2, "0")}`;

    const companyList = ["ACTEAM-IT", "1, 2, 3 PORTAGE", "ACTIMM-RE", "ACTIMM-INV", "MACELIA - INV"];


    const formik = useFormik({
        initialValues: {
            startDate: defaultMonthYear,
            lenght: forecastInfo ? forecastInfo.lenght : 12,
            name: forecastInfo ? forecastInfo.forecastName : "",
            society: forecastInfo ? forecastInfo.society : ""
        },


        onSubmit: async (values: IForecastFormValues) => {
            values.name = values.name.trim();
            if (values.lenght < 12) {
                dispatch(showSnackbar("La durée du prévisionnel doit être de 12 mois minimum", "error"));
                return;
            }
            if (values.name.length < 1) {
                dispatch(showSnackbar("Le nom est obligatoire", "error"));
                return;
            }

            if (values.society.length < 1) {
                dispatch(showSnackbar("La société est obligatoire", "error"));
                return;
            }

            handleSubmit({ ...values, society: values.society }, token, setOpenModal, dispatch, setForecastData, setError, forecastInfo?.id);
        },

    });



    const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        formik.setFieldValue("startDate", value);
    };



    return (
        <Box component="form" onSubmit={formik.handleSubmit} sx={{ display: "flex", flexDirection: "column", justifyContent: "center", padding: "20px" }}>
            {!forecastInfo &&

                <FormControl fullWidth sx={{ mt: 2 }}>
                    <InputLabel id="society-label">Société</InputLabel>
                    <Select
                        required
                        labelId="society-label"
                        value={formik.values.society}
                        onChange={(e) => formik.setFieldValue("society", e.target.value)}
                        label="Nom"
                        name="name"
                    >
                        {companyList.map((company) => (
                            <MenuItem key={company} value={company}>
                                {company}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            }
            {!forecastInfo &&
                (<TextField
                    margin="normal"
                    label="Date de début"
                    required
                    type="month"
                    id="startDate"
                    name="startDate"
                    value={formik.values.startDate}
                    onChange={handleDateChange}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    error={formik.touched.startDate && Boolean(formik.errors.startDate)}
                    autoFocus
                />)
            }
            <TextField
                margin="normal"
                required
                type="number"
                id="lenght"
                label="Durée (en nombre de mois)"
                name="lenght"
                autoComplete="lenght"
                value={formik.values.lenght}
                onChange={formik.handleChange}
                error={formik.touched.lenght && Boolean(formik.errors.lenght)}
                autoFocus
            />
            <TextField
                margin="normal"
                type="text"
                id="name"
                required
                label="Nom"
                name="name"
                autoComplete="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                error={formik.touched.name && Boolean(formik.errors.name)}
                autoFocus
            />
            <Button type="submit" variant="contained" sx={{ mt: 3, mb: 2 }}>Enregistrer</Button>
        </Box>
    )
}

export default AddUpdateForecast;