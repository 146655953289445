import { getAll} from "../../fetchs/get";
import { sessionEnd } from "../../redux/actions";

export default async function getCompanyData (
    forecastId : string | undefined,
    forecastVersion: number | undefined,
    setCompanyData : Function,
    token : string | undefined,
    dispatch : Function,
    setError : Function
) {
    if(token){
        getAll(token, '/forecast/'+forecastId+"/"+forecastVersion+'/company', setCompanyData, 
            () => {
                dispatch(sessionEnd());
            }, setError
        )
    }
}