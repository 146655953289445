// React
import { useState, useEffect } from "react";

// Redux
import { useSelector } from "react-redux";
import { RootState } from "../../redux/reducers/index";
import { useDispatch } from "react-redux";
import { sessionEnd, showSnackbar } from "../../redux/actions/index";

// MaterialUI
import { Typography, Input, Autocomplete, TextField, Divider } from "@mui/material";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import List from "@mui/material/List";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import EditIcon from "@mui/icons-material/Edit"

// Icons
import SearchIcon from "@mui/icons-material/Search";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CleaningServicesIcon from "@mui/icons-material/CleaningServices";

// Fetch
import { getOne } from "../../fetchs/get";
import { deleteOne } from "../../fetchs/delete";
import { fetchPostProtect } from "../../fetchs/post";

// Interface
import { MatriceWrapperClientsData } from "../../interface/matrice";
import MatrixDialogEdit from "./MatrixDialogEdit";

/** Barre de recherche et de filtre pour les matrices */
const SearchFilterBar = ({
  handleSearchTermChange,
  handleClientSelectionChange,
  selectedClients,
  uniqueClients,
  handlePersonalMatriceChange,
  updateOneMatrix,
  getAllMatrix,
}: {
  handleSearchTermChange: Function;
  handleClientSelectionChange: Function;
  selectedClients: string[];
  uniqueClients: { name: string }[];
  handlePersonalMatriceChange: Function;
  updateOneMatrix: Function;
  getAllMatrix: Function;
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [masterMatrice, setMasterMatrice] = useState<null | MatriceWrapperClientsData>(null);
  const [openModal, setOpenModal] = useState<Boolean>(false);

  const dispatch = useDispatch();
  const menuOpen = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const token = useSelector((state: RootState) => {
    return state.user.token;
  });

  /** Requete pour récupérer la matrice master (matrice par défaut) */
  const getMasterMatrice = () => {
    if (token && !masterMatrice) {
      getOne(
        token,
        "/matrice/master/",
        "project_master",
        setMasterMatrice,
        () => {
          dispatch(sessionEnd());
        },
        (err: any) => {
          dispatch(showSnackbar("Erreur lors de la master matrice : " + err?.error, "error"));
        }
      );
    }
  };

  /** Requete pour supprimer toutes les matrices non utilisées */
  const deleteUnusedMatrice = () => {
    if (token) {
      deleteOne(token, "/matrice/deleteUnused").then((res) => {
        if (res.status === 200) {
          getAllMatrix(true);
          res.json().then((data) => {
            dispatch(showSnackbar(data.message + " matrice(s) supprimée(s)", "success"));
          });
        } else if (res.status === 401) {
          dispatch(sessionEnd());
        } else {
          res.json().then((err) => {
            dispatch(showSnackbar("Erreur lors de la suppression : " + err.error, "error"));
          });
        }
      });
    }
  };

  useEffect(() => {
    getMasterMatrice();
  }, [masterMatrice]);

  /**
   * Envoie la matrice master modifiée au back pour la mettre à jour dans la base de données
   * Ouverture d'une snackbar en fonction du résultat de la requête
   * @param updatedMatriceData matrice master modifiée
   */

  const pushModifiedMasterMatrice = (updatedMatriceData: MatriceWrapperClientsData) => {
    const body: MatriceWrapperClientsData = { ...updatedMatriceData };
    if (token) {
      fetchPostProtect(token, "/matrice/master/update/project_master", JSON.stringify(body)).then((res) => {
        if (res.status === 200) {
          dispatch(showSnackbar("Matrice master modifiée avec succès", "success"));
        } else {
          res.json().then((err) => {
            console.error(err);
            dispatch(showSnackbar("Erreur lors de la modification de la matrice master : " + err.error, "error"));
          });
        }
      });
    }
  };

  return (
    <>
      <List
        sx={{
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          flexDirection: "row",
          padding: 0,
          bgcolor: "rgba(0,0,0,0.1)",
          borderRadius: 4,
          height: "fit-content",
          px: 2,
          mb: 2,
          "& > *:not(:last-child)": {
            mr: 2,
          },
          "& > *": {
            my: 1,
          },
        }}
      >
        <Typography
          variant="h6"
          component="div"
          sx={{ whiteSpace: "nowrap", height: 45, display: "flex", alignItems: "center" }}
        >
          Matrices enregistrées
        </Typography>

        <Box
          sx={{
            bgcolor: "white",
            borderRadius: 4,
            height: 40,
            pl: 2,
            py: 1,
            display: "flex",
            width: "fit-content",
          }}
        >
          <SearchIcon></SearchIcon>
          <Input
            placeholder="Rechercher une matrice"
            onChange={(event) => handleSearchTermChange(event.target.value)}
            disableUnderline
            sx={{ width: "fit-content", minWidth: 200, ml: 1 }}
          />
        </Box>

        <Box
          sx={{
            bgcolor: "white",
            borderRadius: 4,
            height: 40,
            px: 2,
            display: "flex",
            width: "fit-content",
            alignItems: "center",
          }}
        >
          <Autocomplete
            multiple
            id="tags-standard"
            freeSolo
            value={selectedClients}
            sx={{ width: "fit-content", borderRadius: 6 }}
            options={uniqueClients.map((client) => client.name)}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => <Chip label={option} {...getTagProps({ index })} />)
            }
            renderInput={(params) => (
              <TextField
                variant="standard"
                placeholder="Trier par client"
                {...params}
                style={{ minWidth: 300 }}
                InputProps={{
                  ...params.InputProps,
                  disableUnderline: true,
                  sx: { 
                    "&&#tags-standard": {
                      minWidth: 110,
                    },
                  }
                }}
              />
            )}
            onChange={(event, value) => handleClientSelectionChange(value)}
          />
        </Box>

        <Box
          sx={{
            position: "absolute",
            right: 10,
          }}
        >
          <IconButton
            aria-label="more"
            id="long-button"
            aria-controls={menuOpen ? "long-menu" : undefined}
            aria-expanded={menuOpen ? "true" : undefined}
            aria-haspopup="true"
            onClick={handleClick}
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={menuOpen}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem key={"displayOwn"} sx={{ mx: 1 }}>
              <FormControlLabel
                control={
                  <Checkbox onChange={(e: React.ChangeEvent<HTMLInputElement>) => handlePersonalMatriceChange(e)} />
                }
                label="Afficher uniquement vos matrices"
              />
            </MenuItem>
            <Divider />
            {masterMatrice && (
              <MenuItem key={"editMaster"} sx={{ m: 1, py: 1 }} onClick={() => setOpenModal(true)}>
                <ListItemIcon>
                  <EditIcon />
                </ListItemIcon>
                <ListItemText sx={{ width: "fit-content" }}>Editer la matrice de base</ListItemText>
              </MenuItem>
            )}
            <MenuItem key={"cleanNotUsed"} sx={{ m: 1, py: 1 }} onClick={deleteUnusedMatrice}>
              <ListItemIcon>
                <CleaningServicesIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Nettoyer les matrices non utilisées" />
            </MenuItem>
          </Menu>
        </Box>
      </List>
      {openModal && masterMatrice && (
        <MatrixDialogEdit
          matrice={masterMatrice}
          setOpenModal={setOpenModal}
          pushModifiedMatrice={pushModifiedMasterMatrice}
          isMaster={true}
          updateOneMatrix={updateOneMatrix}
        />
      )}
    </>
  );
};

export default SearchFilterBar;