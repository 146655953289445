// MaterialUI
import { Card, Box, Typography } from "@mui/material";

// Img
import image from "../../img/hello.webp";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import PriceChangeIcon from "@mui/icons-material/PriceChange";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";

const Home = ({ isForecastAccessible }: { isForecastAccessible: boolean }) => {
  const menu = [
    {
      icon: <PriceChangeIcon sx={{ width: "100%", height: "100px" }} />,
      title: "Chiffrage Projets",
      link: "/chiffrageCDC",
      disabled: false
    },
    {
      icon: <CalendarMonthIcon sx={{ width: "100%", height: "100px" }} />,
      title: "Chiffrage Prévisionnel",
      link: "/prévisionnel",
      disabled: !isForecastAccessible // Utilisation de la prop
    }
  ];

  let navigate = useNavigate();

  const user = useSelector((state: any) => state.user);

  console.log("user", user);

  return (
    <div>
      <Card
        component="main"
        sx={{
          backgroundColor: "white",
          flexGrow: 1,
          p: 2,
          m: 1,
          height: "90vh"
        }}
      >
        <Typography variant="h2" sx={{ textAlign: "center" }}>
          Bienvenue sur Kairos
        </Typography>

        <Box sx={{ textAlign: "center", marginTop: "50px" }}>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-evenly",
              margin: "auto"
            }}
          >
            {menu.map((item, index) => (
              <Box
                key={index}
                sx={{
                  width: "350px",
                  height: "200px",
                  backgroundColor: "#F2F2F2",
                  borderRadius: "20px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-evenly",
                  alignItems: "center",
                  opacity: item.disabled ? 0.5 : 1,
                  pointerEvents: item.disabled ? "none" : "auto",
                  "&:hover": {
                    cursor: item.disabled ? "not-allowed" : "pointer",
                    filter: item.disabled ? "none" : "invert(1)"
                  }
                }}
                onClick={() => {
                  if (!item.disabled) {
                    navigate(item.link);
                  }
                }}
              >
                {item.icon}
                <Typography variant="subtitle2" sx={{ fontSize: "1.8em" }}>
                  {item.title}
                </Typography>
              </Box>
            ))}
          </Box>
        </Box>
      </Card>
    </div>
  );

};

export default Home;
