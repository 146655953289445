import { DataGrid, getGridStringOperators, GridColDef, GridRowId, GridRowsProp } from "@mui/x-data-grid";
import getForecastMonthList from "../../../tools/forecast/getForecastMonthList";
import { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import { ITurnover } from "../../../interface/turnover";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/reducers";
import getTurnoverData from "../../../tools/forecast/getTurnoverData";
import { useParams } from "react-router";
import { setTotalByMonth } from "../../../redux/actions/forecastActions";
import CustomDataGrid from "../../customDataGrid/CustomDataGrid";

const TurnoverData = ({
    forecastVersion,
    forecastDates,
    type,
    agency,
    setSelectedTurnover,
    openModal,
    showDeleteDialog,
    setTurnoverId,
    setOpenModal,
    setSelectedData,
    selectedData,
    setEditId


}: {
    forecastVersion: number | undefined,
    forecastDates: string,
    type: string,
    agency: string | null,
    setSelectedTurnover: Function,
    openModal: boolean,
    showDeleteDialog: boolean,
    setTurnoverId: Function,
    setOpenModal: Function,
    setSelectedData: Function,
    selectedData: string[],
    setEditId: Function
}) => {

    let dispatch = useDispatch();

    let token = useSelector((state: RootState) => {
        return state.user.token;
    });
    const [error, setError] = useState("");
    const params = useParams();

    const [turnoverData, setTurnoverData] = useState<ITurnover[]>([]);
    const monthList = getForecastMonthList(forecastDates);
    const [selected, setSelected] = useState<GridRowId[]>([]);

    useEffect(() => {
        getTurnoverData(params.id, forecastVersion, agency, type, setTurnoverData, token, dispatch, setError);
    }, [agency, forecastVersion, openModal, showDeleteDialog, selectedData]);

    const handleRowSelectionChange = (newSelection: any) => {
        const newSelectedTurnover = { [type]: newSelection };
        setSelectedTurnover((prevSelectedTurnover: any) =>
            Object.assign({}, prevSelectedTurnover, newSelectedTurnover)
        );
    };

    const columnsToDisplay = {
        infos: [
            { name: "Agence", field: "agency" },
            { name: "Client", field: "client" },
            { name: type === "Régie" ? "Collaborateur" : "Projet", field: type === "Régie" ? "collab" : "project" }
        ],
        months: monthList
    }

    const columnsInfos: GridColDef[] = columnsToDisplay.infos.map((column) => ({
        field: column.field,
        headerName: column.name,
        filterable: true,
        minWidth: 200,
        filterOperators: getGridStringOperators().filter((operator) => operator.value === "contains"),
        headerClassName: "custom-header",
        renderCell: (params) => {
            let value = params.value;
            if (value == null) {
                value = "";
            }
            if (typeof value !== "string") {
                value = String(value);
            }
            const lines = value.split('\n');

            return (
                <div style={{ whiteSpace: "normal" }}>
                    {lines.map((line: string, index: number) => (
                        <span key={index}>
                            {line}
                            <br />
                        </span>
                    ))}
                </div>
            );
        },
    }));


    const columnsMonth: GridColDef[] = columnsToDisplay.months.map((column) => {
        return {
            field: column.field,
            headerName: column.name,
            minWidth: 160,
            align: 'center',
            headerAlign: 'center',
            headerClassName: "custom-header",
            renderCell: (params) => (
                <div style={{ whiteSpace: 'normal' }}>
                    {params.value.split('\n').map((line: any, index: any) => (
                        <span key={index}>
                            {line}
                            <br />
                        </span>
                    ))}
                </div>
            ),
        }
    })

    const columns: GridColDef[] = [...columnsInfos, ...columnsMonth];

    const turnoverRows: GridRowsProp = turnoverData.map((turnover: ITurnover) => {
        const turnoverRow: any = {
            id: turnover._id,
            agency: turnover.agency ? turnover.agency : "Non précisée",
            client: turnover.client.name,
        };

        let monthExpenses;
        if (turnover.type === "Régie") {
            turnoverRow["collab"] = turnover.collab ? turnover.collab.firstname + " " + turnover.collab.lastname : turnover.fictifCollab;
            monthExpenses = Object.fromEntries(
                Object.entries(turnover.months).map(([key, value]) => [value.label, `Nb JO : ${value.openDays}\nTJM : ${value.tjm} €/JO\nTotal : ${value.openDays * value.tjm} €`])
            );
        }
        else {
            turnoverRow["project"] = turnover.project ? turnover.project : "Non précisé";
            
            monthExpenses = Object.fromEntries(
                Object.entries(turnover.months).map(([key, value]) => [value.label, `\n${value.amount} €\n`])
            );
        }
        return { ...turnoverRow, ...monthExpenses };
    });

    const totalByMonth = monthList.reduce((acc: any, item: any) => {
        acc[item.field] = turnoverData.reduce((sum: number, turnover: any) => {
            const matchingMonth = turnover.months.find((month: any) => month.label === item.field);
            if (matchingMonth) {
                if (turnover.type === "Régie") {
                    return sum + (matchingMonth.openDays * matchingMonth.tjm || 0);
                }
                else {
                    return sum + (matchingMonth.amount || 0);
                }
            }
            return sum;
        }, 0);
        return acc;
    }, {})

    dispatch(setTotalByMonth(type, totalByMonth));

    const totalRow = {
        id: "total",
        collab: `\nTOTAL ${type.toUpperCase()}\n`,
        project: `\nTOTAL ${type.toUpperCase()}\n`,
        ...Object.fromEntries(
            Object.entries(totalByMonth).map(([key, value]) => [key, `${value} €`])
        )
    };

    const rows: GridRowsProp = turnoverData.length > 0 ? [...turnoverRows, totalRow] : [...turnoverRows];

    if (turnoverData.length > 0) {
        return (
            <>
                <Typography variant="h5" sx={{ textAlign: "center", backgroundColor: "black", color: "white" }}>{type}</Typography>
                <CustomDataGrid
                    disableRowSelectionOnClick={true}
                    rows={rows}
                    columns={columns}
                    onCellClick={(params) => { if (params.row.field !== "__check__" && params.row.id !== 'total') { setEditId(params.row.id); setOpenModal(true); } }}
                    checkboxSelection
                    rowSelectionModel={selectedData}
                    onRowSelectionModelChange={(newSelection) => {
                        setSelected(newSelection);
                        handleRowSelectionChange(newSelection);
                        setSelectedData(newSelection);
                    }}

                    isRowSelectable={(params) => {
                        return params.row.id !== 'total';
                    }}

                />

            </>
        )
    }
    else {
        return <></>
    }
}

export default TurnoverData;