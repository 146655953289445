const initialState = {
    totals: {}
};

export const forecastReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case 'SET_TOTAL_BY_MONTH': {
            const { category, totalByMonth } = action.payload;
            return {
                ...state,
                totals: {
                    ...state.totals,
                    [category]: totalByMonth // Met à jour uniquement la catégorie ciblée
                }
            };
        }
        default:
            return state;
    }
};