import { DataGrid, getGridStringOperators, GridColDef, GridRowsProp } from "@mui/x-data-grid";
import getForecastMonthList from "../../../tools/forecast/getForecastMonthList";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/reducers";
import { useParams } from "react-router";
import { ITNS } from "../../../interface/tns";
import { ICompany } from "../../../interface/company";
import getCompanyData from "../../../tools/forecast/getCompanyData";
import { setTotalByMonth } from "../../../redux/actions/forecastActions";
import CustomDataGrid from "../../customDataGrid/CustomDataGrid";

const CompanyData = ({
    forecastVersion,
    forecastDates,
    selectedCompany,
    setSelectedCompany,
    openModal,
    showDeleteDialog,
    setEditId,
    setOpenModal
}: {
    forecastVersion: number | undefined,
    forecastDates: string,
    selectedCompany: string[],
    setSelectedCompany: Function,
    openModal: boolean,
    showDeleteDialog: boolean,
    setEditId: Function,
    setOpenModal: Function
}) => {
    let dispatch = useDispatch();

    let token = useSelector((state: RootState) => {
        return state.user.token;
    });
    const [error, setError] = useState("");
    const params = useParams();

    const [companyData, setCompanyData] = useState<any[]>([]);
    const monthList = getForecastMonthList(forecastDates);

    useEffect(() => {
        getCompanyData(params.id, forecastVersion, setCompanyData, token, dispatch, setError);
    }, [forecastVersion, openModal, showDeleteDialog, selectedCompany]);

    const columnsToDisplay = {
        infos: [
            { name: "Libellé", field: "label" },
        ],
        months: monthList
    }

    const columnsInfos: GridColDef[] = columnsToDisplay.infos.map((column) => {
        return {
            field: column.field,
            headerName: column.name,
            filterable: true,
            minWidth: 200,
            filterOperators: getGridStringOperators().filter((operator) => operator.value === "contains"),
            headerClassName: "custom-header",
            renderCell: (params) => (
                <div style={{ whiteSpace: 'normal' }}>
                    {params.value?.split('\n').map((line: any, index: any) => (
                        <span key={index}>
                            {line}
                            <br />
                        </span>
                    ))}
                </div>
            ),
        }
    })

    const columnsMonth: GridColDef[] = columnsToDisplay.months.map((column) => {
        return {
            field: column.field,
            headerName: column.name,
            minWidth: 160,
            headerClassName: "custom-header",
        }
    })

    const columns: GridColDef[] = [...columnsInfos, ...columnsMonth];

    const turnoverRows: GridRowsProp = companyData.map((tns: ITNS) => {
        const turnoverRow: any = {
            id: tns._id,
            label: `\n${tns.label}\n`,
        };

        const monthExpenses = Object.fromEntries(
            Object.entries(tns.months).map(([key, value]) => {
                if (typeof value === "object" && value !== null) {
                    const obj = value as { label: string; value: number };
                    return [obj.label, `\n${obj.value} €\n`];
                }
                return [key, `\n${value} €\n`];
            })
        );

        return { ...turnoverRow, ...monthExpenses };
    });



    const totalByMonth = monthList.reduce((acc: any, item: any) => {
        acc[item.field] = companyData.reduce((sum, data) => {
            const monthData = data.months.find((month: any) => month.label === item.field);
            return sum + (monthData ? monthData.value : 0);
        }, 0);
        return acc;
    }, {});

    dispatch(setTotalByMonth("Entreprise", totalByMonth));

    const totalRow = {
        id: "total",
        label: `\nTOTAL\n`,
        ...Object.fromEntries(
            Object.entries(totalByMonth).map(([key, value]) => [key, `\n${value} €\n`])
        )
    };

    const rows: GridRowsProp = companyData.length > 0 ? [...turnoverRows, totalRow] : [...turnoverRows];

    return (
        <>
            <CustomDataGrid
                disableRowSelectionOnClick={true}
                rows={rows}
                columns={columns}
                getRowHeight={() => 'auto'}
                onCellClick={(params) => { if (params.row.field !== "__check__" && params.row.id !== 'total') { setEditId(params.row.id); setOpenModal(true); } }}
                checkboxSelection
                rowSelectionModel={selectedCompany}
                onRowSelectionModelChange={(selectionModel) => { setSelectedCompany(selectionModel) }}
                isRowSelectable={(params) => {
                    return params.row.id !== 'total';
                }}

            />
        </>
    )
}

export default CompanyData;