import { getAll} from "../../fetchs/get";
import { sessionEnd } from "../../redux/actions";

export default async function getForecastSalariesData (
    forecastId : string | undefined,
    version: number | undefined,
    agency: string | null, 
    setSalaries : Function,
    token : string | undefined,
    dispatch : Function,
) {
    if(token){
        getAll(token, '/forecast/'+forecastId+"/"+version+'/salaries/'+agency, setSalaries, 
            () => {
                dispatch(sessionEnd());
            }, ()=>{}
        )
    } 
}