import axios from "axios";
import { urlAPI } from "../../config/config";
import { showSnackbar } from "../../redux/actions";

export const duplicateTurnover = async (
    turnoverId: string, 
    token: string | undefined, 
    setOpenModal: Function,
    dispatch: Function,
) => {

    try {
        const response = await axios.post(`${urlAPI}/forecast/turnover/duplicate/${turnoverId}`,
            {},
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );        
        setOpenModal(false);
        dispatch(showSnackbar("Prestation dupliqué avec succès.", "success"));
        
      } catch (error: any) {
        if (error.response) {
          console.error("Erreur API :", error.response.data);
          console.error("Statut :", error.response.status);
        } else if (error.request) {
          console.error("Erreur de requête :", error.request);
        } else {
          console.error("Erreur :", error.message);
        }
  
        dispatch(showSnackbar("Erreur lors de la duplication.", "error"));
    }
}