import axios from "axios";
import { ITurnoverFormValues } from "../../interface/turnover";
import { showSnackbar } from "../../redux/actions";
import { urlAPI } from "../../config/config";

export const addUpdateTurnover =  async (
    token: string,
    values: ITurnoverFormValues,
    forecastId: string,
    version: number,
    setOpenModal: Function,
    dispatch: Function,
    setError: Function,
    turnoverId? : string
) => {
    try {
        if (!token || !forecastId) {
            console.error("Token ou Forecast ID manquant.");
            setError("Token ou Forecast ID manquant. Veuillez réessayer.");
            return;
        }
        if (values.type === "Régie") {
          values.project = "";
          values.months = values.months.map((month) => ({
              ...month,
              amount: 0,
          }));
        } else {
          values.collab = "";
          values.fictifCollab = "";
          values.months = values.months.map((month) => ({
              ...month,
              openDays: 0,
              tjm: 0,
          }));
        }
        if(!turnoverId){
          const response = await axios.post(
            `${urlAPI}/forecast/turnover/add`,
            { ...values, forecastId, forecastVersion: version, },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
        }
        else{
          const response = await axios.post(
            `${urlAPI}/forecast/turnover/update/${turnoverId}`,
            { ...values, forecastId, forecastVersion: version, },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
        }
  
        setOpenModal(false);
  
        dispatch(showSnackbar("Prestation enregistrée avec succès.", "success"));
    } catch (error: any) {
      
      if (error.response) {
        console.error("Erreur API :", error.response.data);
        console.error("Statut :", error.response.status); 
      } else if (error.request) {
        console.error("Erreur de requête :", error.request);
      } else {
        console.error("Erreur :", error.message);
      }

      setError("Impossible d'enregistrer la prestation. Veuillez réessayer.");
      dispatch(showSnackbar("Erreur lors de l'enregistrement.", "error"));
    }
  };