import { Autocomplete, Button, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { useFormik } from "formik";
import * as yup from "yup";
import { IForecast, IForecastDuplicateValues, IForecastFormValues } from "../../../interface/forecast";
import { useNavigate } from "react-router";
import { fetchPostProtect } from "../../../fetchs/post";
import { sessionEnd, showSnackbar } from "../../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/reducers";
import { useState } from "react";
import getForecastsData from "../../../tools/forecast/getForecastsData";
import { GridRowsProp } from "@mui/x-data-grid";


const DuplicateForecast = ({
    setOpenModal,
    forecastData,
    handleSubmit,
    setForecastData
}: {
    setOpenModal: Function,
    forecastData: GridRowsProp,
    handleSubmit: Function,
    setForecastData: Function
}) => {
    let navigate = useNavigate();
    let dispatch = useDispatch();
    const [error, setError] = useState('');
    const token = useSelector((state: RootState) => {
        return state.user.token;
    });

    const [selectedForecast, setSelectedForecast] = useState<IForecast>();
    const validationSchema = yup.object({
        forecastId: yup.string().required("Le prévisionnel de référence est obligatoire"),
        startDate: yup.date().required("La date de début est obligatoire"),
        lenght: yup.number().required("La date de début est obligatoire"),
    });

    const defaultDate = new Date();
    const defaultMonthYear = `${defaultDate.getFullYear()}-${(defaultDate.getMonth() + 1).toString().padStart(2, "0")}`;



    const formik = useFormik({
        initialValues: {
            forecastId: forecastData ? forecastData[0].id : "",
            startDate: defaultMonthYear,
            society: "ACTEAM-IT",
            lenght: forecastData ? forecastData[0].lenght : 0,
            versions: forecastData ? forecastData[0].versions : [{ number: 1, official: true }],
        },
        validationSchema: validationSchema,
        onSubmit: async (values: IForecastDuplicateValues) => {

            handleSubmit(values, token, setOpenModal, dispatch, setForecastData, setError);
        },
        enableReinitialize: true
    });

    const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        formik.setFieldValue("startDate", value);
    };

    return (
        <Box component="form" onSubmit={formik.handleSubmit} sx={{ display: "flex", flexDirection: "column", justifyContent: "center", padding: "20px" }}>
            <Autocomplete
                id="forecast"
                options={forecastData}
                getOptionLabel={(option) => option.name}
                isOptionEqualToValue={(option, value) => option._id === value._id}
                renderOption={(props, option) => {
                    return (
                        <li {...props} key={option.id}>
                            {option.name}
                        </li>
                    );
                }}
                defaultValue={forecastData[0]}
                onChange={(event, value: any) => {
                    if (value && value.id) {
                        formik.setFieldValue("forecastId", value.id);
                        formik.setFieldValue("lenght", value.lenght);
                        formik.setFieldValue("versions", value.versions);
                    } else {
                        formik.setFieldValue("forecastId", "");
                        formik.setFieldValue("forecastName", "");
                        formik.setFieldValue("lenght", 0);
                        formik.setFieldValue("versions", []);
                    }
                }}

                renderInput={(params) => (
                    <TextField
                        {...params}
                        required
                        name="forecast"
                        margin="normal"
                        label="Prévisionnel de référence"

                    />
                )}
            />
            <TextField
                margin="normal"
                label="Nouvelle date de début"
                required
                type="month"
                id="startDate"
                name="startDate"
                value={formik.values.startDate}
                onChange={handleDateChange}
                InputLabelProps={{
                    shrink: true,
                }}
                error={formik.touched.startDate && Boolean(formik.errors.startDate)}
                autoFocus
            />
            <Button type="submit" variant="contained" sx={{ mt: 3, mb: 2 }}>Enregistrer</Button>
        </Box>
    )
}

export default DuplicateForecast;