// Icons
import FilterListRoundedIcon from "@mui/icons-material/FilterListRounded";
import SearchIcon from '@mui/icons-material/Search';
// Material UI
import { Typography, Box, Stack, Icon } from "@mui/material";
import { Button, buttonClasses } from '@mui/base/Button';
import { styled } from '@mui/system';

// React
import { useState, useEffect } from "react";
import dayjs, { Dayjs } from "dayjs";

// Redux
import { useDispatch } from "react-redux";
import { showSnackbar } from "../../redux/actions/index";




// Components
import UserFilter from "./Filters/UserFilter";
import ActionFilter from "./Filters/ActionFilter";
import CodeFilter from "./Filters/CodeFilter";
import EntityFilter from "./Filters/EntityFilter";
import DateFilter from "./Filters/DateFilter";
import SelectedFilters from "./SelectedFilters";

// Fetch
import { fetchGetProtect } from "../../fetchs/get";

const orange = {
  500: '#ff9800',
  600: '#fb8c00',
  700: '#f57c00',
};

const grey = {
  100: '#eaeef2',
  300: '#afb8c1',
  900: '#24292f',
};

const CustomButton = styled(Button)(
  ({ theme }) => `
  font-family: IBM Plex Sans, sans-serif;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.5;
  background-color: ${orange[500]};
  padding: 8px 16px;
  border-radius: 8px;
  color: white;
  transition: all 150ms ease;
  cursor: pointer;
  border: none;
  display: inline-flex;
  align-items: center;
  box-shadow: 0px 4px 30px ${theme.palette.mode === 'dark' ? grey[900] : grey[100]};

  &:hover {
    background-color: ${orange[600]};
  }

  &.${buttonClasses.active} {
    background-color: ${orange[700]};
  }

  &.${buttonClasses.focusVisible} {
    box-shadow: 0 3px 20px 0 rgba(61, 71, 82, 0.1), 0 0 0 5px rgba(0, 127, 255, 0.5);
    outline: none;
  }

  &.${buttonClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }
  `,
);

const FiltersWrapper = ({
  token,
  setHistoryData,
  ready,
  setReady,
  postSearch,
  currentNames,
  setCurrentNames,
  currentActions,
  setCurrentActions,
  currentStatus,
  setCurrentStatus,
  currentEntities,
  setCurrentEntities,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
}: {
  token: string | undefined;
  setHistoryData: Function;
  ready: boolean;
  setReady: Function;
  postSearch: Function;
  currentNames: string[];
  setCurrentNames: React.Dispatch<React.SetStateAction<string[]>>;
  currentActions: string[];
  setCurrentActions: React.Dispatch<React.SetStateAction<string[]>>;
  currentStatus: string[];
  setCurrentStatus: React.Dispatch<React.SetStateAction<string[]>>;
  currentEntities: string[];
  setCurrentEntities: React.Dispatch<React.SetStateAction<string[]>>;
  startDate: Dayjs;
  setStartDate: React.Dispatch<React.SetStateAction<dayjs.Dayjs>>;
  endDate: Dayjs;
  setEndDate: React.Dispatch<React.SetStateAction<dayjs.Dayjs>>;

}) => {
  const [names, setNames] = useState<string[]>([]);
  const [actions, setActions] = useState<string[]>([]);
  const [status, setStatus] = useState<string[]>([]);
  const [entities, setEntities] = useState<string[]>([]);

  const dispatch = useDispatch();

  useEffect(() => {
    getFilters();
  }, []);

  const getFilters = () => {
    if (token) {
      fetchGetProtect(token, "/history/filters").then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            setNames(data.names);
            setActions(data.actions);
            setStatus(data.status);
            setEntities(data.entities);

            postSearch();
          });
        } else {
          res.json().then((err) => {
            console.error(err);
            dispatch(showSnackbar("Erreur lors de la récupération des filtres : " + err.error, "error"));
          });
        }
      });
    }
  };

  return (
    <Box sx={{ mb: 1 }}>
      {ready && <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Stack
          direction="row"
          spacing={3}
          sx={{ px: 2, flexWrap: "wrap", maxWidth: "100%", pb: 1 }}
          justifyContent="flex-start"
          alignItems="center"
        >
          <Box sx={{ display: "flex", alignItems: "center", mr: 4 }}>
            <FilterListRoundedIcon color="primary" sx={{ mr: 1 }} />
            <Typography variant="subtitle2" color="primary" sx={{ display: "inline-block" }}>
              Filtres
            </Typography>
          </Box>

          <UserFilter personName={currentNames} setPersonName={setCurrentNames} names={names} />

          <ActionFilter currentActions={currentActions} setCurrentActions={setCurrentActions} actions={actions} />

          <CodeFilter currentCodes={currentStatus} setCurrentCodes={setCurrentStatus} codes={status} />

          <EntityFilter currentEntities={currentEntities} setCurrentEntities={setCurrentEntities} entities={entities} />

          <DateFilter startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate} />
        </Stack>
        <CustomButton onClick={postSearch} sx={{ mb: 2 }}>
          <Icon
            component={SearchIcon}
            sx={{
              mr: 1,
              fontSize: 18,
            }}
          />
          Rechercher
        </CustomButton>
      </Stack>}

      <SelectedFilters
        currentNames={currentNames}
        setCurrentNames={setCurrentNames}
        currentActions={currentActions}
        setCurrentActions={setCurrentActions}
        currentStatus={currentStatus}
        setCurrentStatus={setCurrentStatus}
        currentEntities={currentEntities}
        setCurrentEntities={setCurrentEntities}
      />
    </Box>
  );
};

export default FiltersWrapper;