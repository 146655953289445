import { Switch, TextField, Typography } from "@mui/material"
import { Box } from "@mui/system"
import { useCallback, useEffect, useState } from "react";

type Month = {
    tjm: number;
    amount: number;
    openDays: number;
};

const TurnoverMonthFields = ({
    formik,
    monthList,
    tjm
}: {
    formik: any,
    monthList: { name: string, field: string }[],
    tjm: number
}) => {
    const label = { inputProps: { 'aria-label': 'Switch demo' } };

    const [typeChecked, setTypeChecked] = useState<boolean>(false);
    const [fixedTjm, setFixedTjm] = useState<boolean>(false);
    const [amount, setAmount] = useState<number>(0);
    const [tjmAmount, setTjmAmount] = useState<number>(tjm);






    useEffect(() => {
        if (formik.values.monthlyAmount) {
            setTjmAmount(formik.values.monthlyAmount);
            setAmount(formik.values.monthlyAmount);
        } else {
            setTjmAmount(0);
            setAmount(0);
        }
    }, [formik.values.monthlyAmount]);

    useEffect(() => {
        if (formik.values.type === "Régie") {
            setFixedTjm(!formik.values.months.every((month: Month) => month.tjm === formik.values.months[0].tjm));
        } else {
            setTypeChecked(!formik.values.months.every((month: Month) => month.amount === formik.values.months[0].amount));
        }
    }, [formik.values.months, formik.values.type]);



    const handleFixedChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        const parsedValue = parseFloat(value);

        if (formik.values.type === "Régie") {
            setTjmAmount(parsedValue);
        } else {
            setAmount(parsedValue);
        }

        formik.setFieldValue(
            "months",
            formik.values.months.map((month: Month) => ({
                ...month,
                [name]: parsedValue,
            }))
        );
    };



    const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        const [_, index, field] = name.split("-");
        formik.setFieldValue(`months.${index}.${field}`, value === "" ? "" : Number(value));
    }, [formik]);


    const handleSwitchChange = () => {
        if (formik.values.type === "Régie") {
            setFixedTjm(!fixedTjm)
            if (fixedTjm) {
                monthList.map((key, i) => {
                    formik.values.months[i].tjm = tjmAmount;
                })
            }
        }
        else {
            setTypeChecked(!typeChecked)
            if (typeChecked) {
                monthList.map((key, i) => {
                    formik.values.months[i].amount = amount;
                })
            }
        }
    }


    return (
        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", width: "60%" }}>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Typography variant="h6" sx={{ textAlign: "center", fontWeight: (formik.values.type === "Régie" ? !fixedTjm : !typeChecked) ? "bold" : "" }}>
                    {formik.values.type === "Régie" ? "TJM" : "Montant"} constant
                </Typography>
                <Switch
                    {...label}
                    onClick={handleSwitchChange}
                    checked={(formik.values.type === "Régie" ? fixedTjm : typeChecked) || false}
                />
                <Typography variant="h6" sx={{ textAlign: "center", fontWeight: (formik.values.type === "Régie" ? fixedTjm : typeChecked) ? "bold" : "" }}>
                    {formik.values.type === "Régie" ? "TJM" : "Montant"} variable
                </Typography>
            </Box>
            {(formik.values.type === "Régie" ? !fixedTjm : !typeChecked) && (
                <TextField
                    margin="normal"
                    required
                    type="number"
                    inputProps={{
                        step: "0.1",
                        inputMode: "decimal"
                    }}
                    id={formik.values.type === "Régie" ? "tjm" : "amount"}
                    label={formik.values.type === "Régie" ? "TJM Mensuel" : "Montant Mensuel"}
                    name={formik.values.type === "Régie" ? "tjm" : "amount"}
                    autoComplete={formik.values.type === "Régie" ? "tjm" : "amount"}
                    value={formik.values.type === "Régie" ? tjmAmount : amount}
                    onChange={handleFixedChange}
                    autoFocus
                    sx={{ width: "40%", marginInline: "auto" }}
                />
            )}
            <Box sx={{ display: "flex", flexWrap: "wrap", justifyContent: "left", columnGap: "30px" }}>
                {
                    monthList.map((month, i) => {
                        if (formik.values.type === "Régie") {
                            return (
                                <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", width: "30%" }}>
                                    <Typography variant="subtitle1" sx={{ textAlign: "center" }}>{month.name}</Typography>
                                    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                                        <TextField
                                            margin="normal"
                                            required
                                            type="number"
                                            id={`month-${i}-openDays`}
                                            label={"Jours ouvrés"}
                                            name={`month-${i}-openDays`}
                                            autoComplete={`month-${i}-openDays`}
                                            value={formik.values.months[i].openDays}
                                            onChange={handleChange}
                                            autoFocus
                                            sx={{ width: "45%" }}
                                        />
                                        <TextField
                                            margin="normal"
                                            required
                                            type="number"
                                            id={`month-${i}-tjm`}
                                            label={"TJM"}
                                            name={`month-${i}-tjm`}
                                            autoComplete={`month-${i}-tjm`}
                                            value={formik.values.months[i].tjm}
                                            onChange={handleChange}
                                            autoFocus
                                            disabled={!fixedTjm}
                                            sx={{ width: "45%" }}
                                        />
                                    </Box>
                                </Box>
                            )
                        }
                        else {
                            return (
                                <TextField
                                    margin="normal"
                                    required
                                    type="number"
                                    id={`month-${i}-amount`}
                                    label={month.name}
                                    name={`month-${i}-amount`}
                                    autoComplete={`month-${i}-amount`}
                                    value={formik.values.months[i].amount}
                                    onChange={handleChange}
                                    autoFocus
                                    disabled={!typeChecked}
                                    sx={{ width: "30%" }}
                                />
                            )
                        }
                    })
                }
            </Box>
        </Box>
    )
}

export default TurnoverMonthFields;