import React from "react";
import "./sass/App.scss";

// Layouts
import LayoutPricing from "./layout/LayoutPricing";
import LayoutForecast from "./layout/LayoutForecast";

// Pages
import Login from "./pages/Login/Login";
import Home from "./pages/Homes/Home";
import HomePricing from "./pages/Homes/HomePricing";
import HomeForecast from "./pages/Homes/HomeForecast";
import ListProjects from "./pages/Projects/ListProjects";
import AddProject from "./pages/Projects/AddProject";
import NotFound from "./pages/NotFound";
import Project from "./pages/Projects/Project";
import MatrixHome from "./pages/Matrix/Matrix";
import AddMatrix from "./pages/Matrix/AddMatrix";
import AzureAuth from "./pages/AzureAuth";
import History from "./pages/History/History";
import AddTMA from "./pages/TMA/AddTMA";
import TMA from "./pages/TMA/TMA";
import ListHostings from "./pages/Hebergements/ListHostings";
import AddHosting from "./pages/Hebergements/AddHosting";
import UpdateOvhPrices from "./pages/Hebergements/UpdateOvhPrices";
import Hosting from "./pages/Hebergements/Hosting";
import ForecastDetails from "./pages/Forecast/ForecastDetails";

// Composant Auth
import RequireAuth from "./components/Auth/RequireAuth";

// Composant Snackbar
import SnackbarWrapper from "./components/Snackbar/SnackbarWrapper";

// React router dom
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "./redux/reducers";



function App() {

  const user = useSelector((state: RootState) => state.user);

  console.log(user);
  const env = process.env.REACT_APP_ENV;

  // ✅ Extraction de la logique d’accès au prévisionnel dans une variable
  const isForecastAccessible = env !== "prd" || (user.roles?.includes("directions") ?? false);

  return (
    <BrowserRouter>
      <SnackbarWrapper />
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/azureAuth" element={<AzureAuth />} />
        <Route
          element={
            <RequireAuth>
              <LayoutPricing />
            </RequireAuth>
          }
        >

          <Route path="/chiffrageCDC" element={<HomePricing />} />
          <Route path="/projets" element={<ListProjects />} />
          <Route path="/projets/ajout" element={<AddProject />} />
          <Route path="/projet/:projectId" element={<Project />} />
          <Route path="/TMA" element={<TMA />} />
          <Route path="TMA/ajout" element={<AddTMA />} />
          <Route path="/hebergement" element={<ListHostings />} />
          <Route path="/hebergement/:hostingId" element={<Hosting />} />
          <Route path="/hebergement/ajout" element={<AddHosting />} />
          <Route path="/hebergement/ovh-prices" element={<UpdateOvhPrices />} />
          <Route path="/matrices" element={<MatrixHome />} />
          <Route path="/matrices/ajout" element={<AddMatrix />} />
          <Route path="/history" element={<History />} />
          <Route path="*" element={<NotFound />} />
        </Route>
        <Route element={<RequireAuth><LayoutForecast /></RequireAuth>}>
          {/* La page Home reste toujours accessible */}
          <Route
            path="/"
            element={<Home isForecastAccessible={isForecastAccessible} />}
          />


          {/* Redirection uniquement pour les pages prévisionnelles */}
          {!isForecastAccessible ? (
            <>
              <Route path="/prévisionnel" element={<Navigate to="/" />} />
              <Route path="/prévisionnel/:id/:type?/:version?" element={<Navigate to="/" />} />
            </>
          ) : (
            <>
              <Route path="/prévisionnel" element={<HomeForecast />} />
              <Route path="/prévisionnel/:id/:type?/:version?" element={<ForecastDetails />} />
            </>
          )}
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
