import { getAll} from "../../fetchs/get";
import { sessionEnd } from "../../redux/actions";

export default async function getSubcontractData (
    forecastId : string | undefined,
    forecastVersion: number | undefined,
    agency: string | null,
    setSubcontractData : Function,
    token : string | undefined,
    dispatch : Function,
    setError : Function
) {

    if(token){
        getAll(token, '/forecast/'+forecastId+"/"+forecastVersion+'/subcontract/'+agency, setSubcontractData, 
            () => {
                dispatch(sessionEnd());
            }, setError
        )
    }
}