import axios from "axios";
import { urlAPI } from "../../config/config";
import { showSnackbar } from "../../redux/actions";
import { ITNSFormValues } from "../../interface/tns";
import { normalizeMonths } from "../../components/function/normalizeMonths";

export const addUpdateTNS = async (
    token: string,
    values: ITNSFormValues,
    forecastId: string,
    version: number,
    setOpenModal: Function,
    dispatch: Function,
    setError: Function,
    tnsId? : string
) => {
  const normalizedValues = {
    ...values,
    months: normalizeMonths(values.months)
  };

  console.log("normalizedValues ", normalizedValues);
    try {
        if(!tnsId){
         
          const response = await axios.post(`${urlAPI}/forecast/tns/add`,
            { ...normalizedValues, forecastId, forecastVersion: version },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
        } 
        else{
          const response = await axios.post(`${urlAPI}/forecast/tns/update/${tnsId}`,
            { ...normalizedValues, forecastId, forecastVersion: version },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
        }       
        setOpenModal(false);
        dispatch(showSnackbar("TNS enregistré avec succès.", "success"));
      } catch (error: any) {
        if (error.response) {
          console.error("Erreur API :", error.response.data);
          console.error("Statut :", error.response.status);
        } else if (error.request) {
          console.error("Erreur de requête :", error.request);
        } else {
          console.error("Erreur :", error.message);
        }
  
        setError("Impossible d'enregistrer le frais. Veuillez réessayer.");
        dispatch(showSnackbar("Erreur lors de l'enregistrement.", "error"));
      }
};
