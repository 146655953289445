import axios from "axios";
import { urlAPI } from "../../config/config";

export default async function getSalarialMatrice (
    forecastId : string | undefined,
    forecastVersion: number | undefined,
    setSalarialMatrice : Function,
    token : string | undefined,
) {
    try {
        setSalarialMatrice();
        const syncResponse = await axios.get(`${urlAPI}/forecast/${forecastId}/${forecastVersion}/salarialMatrice`, 
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        setSalarialMatrice(syncResponse.data.message);
    } catch (error: any) {
        const errorMessage = error.message || error.response?.data?.message || "Erreur lors de la mise à jour des salariés.";
    }
}