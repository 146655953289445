import axios from "axios";
import { urlAPI } from "../../config/config";
import { showSnackbar } from "../../redux/actions";
import getForecastSalariesData from "./getForecastSalariesData";
import getForecastMonthList from "./getForecastMonthList";
import getBusinessDaysForMonth from "../getBusinessDays";

export const generateForecastSalaries = async (
    dates: string,
    setSalaries : Function,
    dispatch: Function,
    forecastId?: string,
    version?: number,
    token?: string
) => {
    try {
        const months = {
          "Janvier" : [0, 31],
          "Février" : [1, 28],
          "Mars" : [2, 31],
          "Avril" : [3,30],
          "Mai" : [4, 31],
          "Juin" : [5,30],
          "Juillet" : [6, 31],
          "Août" : [7, 31],
          "Septembre" : [8, 30],
          "Octobre" : [9, 31],
          "Novembre" : [10, 30],
          "Décembre" : [11, 31]
        }
        
        const monthInfos = getForecastMonthList(dates).map((month) => {
          return {label : month.field, openDays : getBusinessDaysForMonth(parseInt(month.name.split(" ")[1]), month.name.split(" ")[0])}
        })

        const startMonthNumber = dates.split("/")[0].split(" ")[0];
        const startMonth = months[startMonthNumber as keyof typeof months][0];
        const endMonthNumber = dates.split("/")[1].split(" ")[1];
        const endMonth = months[endMonthNumber as keyof typeof months][0];
        const endDay = months[endMonthNumber as keyof typeof months][1];

        const start = new Date(parseInt(dates.split("/")[0].split(" ")[1]), startMonth, 1).getTime();
        const end = new Date(parseInt(dates.split("/")[1].split(" ")[2]), endMonth, endDay).getTime();

        const response = await axios.post(`${urlAPI}/forecast/generate/salaries`,
            { start, end, monthInfos, forecastId, forecastVersion: version },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
        );

        await getForecastSalariesData(forecastId, version, "All", setSalaries, token, dispatch);

        dispatch(showSnackbar("Liste des salariés générée avec succés.", "success"));
      } catch (error: any) {
        if (error.response) {
          console.error("Erreur API :", error.response.data);
          console.error("Statut :", error.response.status);
        } else if (error.request) {
          console.error("Erreur de requête :", error.request);
        } else {
          console.error("Erreur :", error.message);
        }
  
        dispatch(showSnackbar("Erreur lors de la génération.", "error"));
      }
};
